import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  // TouchableOpacity,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import _ from "lodash";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import AddSchedule from "./AddSchedule";
import "./css.css";

const context = "voucher";

export default class VoucherHealthpassAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass List",
      is_drawer_open: false,
      selected: null,
      context: context,

      isAdvocacyHealthpass: false,
      isMindHealth: false,
      isAddSchedule: false,
      isGenerateUniqueCode: false,
      isUse24Hours: false,
      isForMwellDoctorsOnly: false,
      isTransferrable: false,
      // validityService: true,
      allowAllUsers: true,

      title: "",
      shortname_code: "",
      short_description: "",
      bundled_sku: [],
      sku_bundle: [],
      quantity: "",
      description: "",
      recurring_days: "",
      validity_schedule: "",
      validity_days: 0,
      status: "",
      maxUsagePerPerson: "",

      arrayOfSchedule: [],
      doctorGroup: [],
      tagging: [],
      allowed_users: [],
      users: [],
      campaigns: [],
      doctor_group: "",
      user_group: "",
      discount: 0,
      price: 0,
      amountCap: 0,
      usabilityVal: "",

      validityServices: {
        cn: false,
        cl: false,
      },

      recurringDays: [],

      startDate: "",
      endDate: "",
      startDateTime: "",
      endDateTime: "",
      startDateTime_dashed: "",
      endDateTime_dashed: "",
      startDateTime_date: "",
      endDateTime_date: "",
      scheduleSuccessMessage: '',
      remember_me: false,
      startBy: ""
    });
  }

  onCreate = () => {
    this.loadDoctorGroup();
    this.loadUserGroup();
    // this.loadTaggingGroup();
    this.loadCampaigns();
    this.load_sku();
  };

  loadDoctorGroup = async () => {
    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {
        names: ['doctors-group', 'advocacy-doctors']
      },
      onFail: async (err) => { },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            doctorGroup: data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  loadUserGroup = async () => {
    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {
        names: ['users-group']
      },
      onFail: async (err) => { },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            userGroup: data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  // loadTaggingGroup = async () => {
  //   this.show_loading();

  //   UI.webRequest({
  //     method: "get",
  //     url: "admin/tags",
  //     params: {},
  //     onFail: async (err) => {},
  //     onSuccess: async (response) => {
  //       const data = UI.extract_data(response);
  //       this.setState(
  //         {
  //           tagging: data.data,
  //         }
  //       );
  //     },
  //     onFinish: () => {
  //       this.hide_loading();
  //     },
  //   });
  // }

  loadCampaigns = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/campaigns",
      params: {},
      onFail: async (err) => { },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            campaigns: data.data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  load_sku = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/healthpass/bundles",
      params: {},
      onFail: async (err) => { },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState(
          {
            sku_bundle: data.data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  load_users = async (name = "") => {
    this.show_loading();
    this.setState({
      isLoading: true,
    });
    UI.webRequest({
      method: "get",
      url: "admin/cms/users?name=" + name,
      params: {},
      onFail: async (err) => { },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const new_data = data.map((item, index) => {
          let name = item?.firstName || item?.lastName ? `${item.firstName} ${item.lastName}` : item.name
          return { label: item.email, name: name, value: item._id };
        });
        this.setState({
          users: new_data,
        });
      },
      onFinish: () => {
        this.hide_loading();
        this.setState({
          isLoading: false,
        });
      },
    });
  };

  saveSchedule = async () => {

    let {
      recurringDays
    } = this.state;


    this.setState({
      arrayOfSchedule: [...this.state.arrayOfSchedule, recurringDays]
    },
      () => {
        this.reload_table("schedule_list");
      })


    // console.log(this.state.arrayOfSchedule)
    // this.setState({
    //   scheduleSuccessMessage:"Successfully add schedule"
    // });

    // setTimeout(() => {
    //   this.setState({
    //     scheduleSuccessMessage: ''
    //   })
    // }, 3000);

  }

  submit = async () => {

    UI.clear_errors();

    let {
      title,
      quantity,
      short_description,
      bundled_sku,
      description,
      status,
      startDate,
      endDate,
      arrayOfSchedule,
      doctor_group,
      discount,
      price,
      amountCap,
      usabilityVal,
      campaign,
      isUse24Hours,
      isForMwellDoctorsOnly,
      isTransferrable,
      maxUsagePerPerson,
      allowAllUsers,
      allowed_users,
      user_group,
      // remember_me,
      shortname_code,
      isAdvocacyHealthpass,
      validityServices,
      validity_days,
      isGenerateUniqueCode,
      isMindHealth,
      startDate_date,
      endDate_date,
      startBy
      // validityService,
    } = this.state;

    let validity_services = []

    if (isAdvocacyHealthpass) {
      if (validityServices.cn) {
        validity_services.push("advocacyCN")
      }

      if (validityServices.cl) {
        validity_services.push("advocacyCL")
      }
    } else {
      if (validityServices.cn) {
        validity_services.push("CN")
      }

      if (validityServices.cl) {
        validity_services.push("CL")
      }
    }

    if (!title) {
      await UI.error_form("title", "Title is required.");
    }

    if (!shortname_code) {
      await UI.error_form("shortname_code", "Shortname Code is required.");
    }

    if (!quantity) {
      await UI.error_form("quantity", "Quantity is required.");
    }

    if (!startDate) {
      await UI.error_form("startDate", "Start Date is required.");
    }

    if (!endDate) {
      await UI.error_form("endDate", "End Date is required.");
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (!startBy) {
      await UI.error_form("startBy", "Start By is required.");
    }

    // if (!doctor_group) {
    //   await UI.error_form("doctor_group", "Select Doctor Group.");
    // }

    // if (!user_group) {
    //   await UI.error_form("user_group", "Select User Group.");
    // }

    if (!campaign) {
      await UI.error_form("campaign", "Select Campaign.");
    }

    if (!maxUsagePerPerson) {
      await UI.error_form("maxUsagePerPerson", "Max Usage Per Person is required.");
    }

    // if (!allowAllUsers) {
    //   if (!user_group) {
    //     await UI.error_form("user_group", "Select User Group.");
    //   }
    //   // if (allowed_users.length === 0) {
    //   //   await UI.error_form(
    //   //     "allowed_users",
    //   //     "Please select at least one user."
    //   //   );
    //   // }
    // }

    const error_count = this.state.error_count;

    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    const start_date = moment(startDate).format("YYYY-MM-DD")
    const end_date = moment(endDate).format("YYYY-MM-DD")

    if (start_date > end_date) {
      return this.show_modal(
        <Failed
          description={"Invalid Start Date and End Date."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      )
    }

    if (validity_services.length === 0) {
      return this.show_modal(
        <Failed
          description={"Validity Service is required."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      )
    }

    if (this.state.isUse24Hours) {
      if (arrayOfSchedule.length === 0) {
        this.show_modal(
          <Failed
            description={"Schedule is required."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
        return;
      }
    }

    this.show_loading();

    let params = {
      // advocacyValidityService: isAdvocacyCN ? "advocacyCN" : "advocacyCL",
      title: title,
      shortName: shortname_code,
      description: description,
      status: status,
      shortDescription: short_description,
      // bundledSKU:bundled_sku,
      // isAdvocacy:isAdvocacyHealthpass,
      // validityService: validityService ? "CN" : "CL",
      validityService: validity_services,
      validityDays: parseInt(validity_days),
      numVouchersToGenerate: !isGenerateUniqueCode ? parseInt(quantity) : 1,
      allowedUsers: allowed_users,
      allowAllUsers: allowAllUsers,
      // initialQuantity: isGenerateUniqueCode ? parseInt(quantity) : 1,
      initialQuantity: parseInt(quantity), // initialQuantity should always be same as supply stock
      availableQuantity: parseInt(quantity), // availableQuantity should always be same as supply stock
      discount: discount,
      maxUsagePerPerson: maxUsagePerPerson,
      price: price ? parseInt(price) : 0,
      campaign: campaign,
      is24Hours: isUse24Hours ? false : true,
      isTransferrable: isTransferrable,
      schedule: arrayOfSchedule,
      startDate: startDate_date,
      endDate: endDate_date,
      startBy,
      createUniqueVouchers: !isGenerateUniqueCode ? true : false,
      isMindHealth: isMindHealth
    }

    if (parseInt(amountCap) > 0) {
      params.amountCap = parseInt(amountCap)
    }

    if (usabilityVal.length > 0) {
      params.usabilityVal = usabilityVal
    }

    if (bundled_sku.length > 0) {
      params.bundledSKU = bundled_sku
    }

    if (isForMwellDoctorsOnly) {
      params.type = isForMwellDoctorsOnly ? "mWellHealthPass" : null
    }

    if (doctor_group.length > 0) params.doctorsGroup = doctor_group

    if (user_group.length > 0) params.patientsGroup = user_group

    UI.webRequest({
      method: "post",
      url: "admin/healthpass",
      params: params,
      onFail: async (err) => {
        this.show_modal(<Failed onDismiss={this.hide_modal} />);
      },
      onSuccess: async (response) => {

        this.show_modal(
          <Success
            descriptionCenter={"Successfully added healthpass."}
            onDismiss={() => {
              window.history.back();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;

    const Users = this.state.users.map((item) => {
      return <option value={item.value}>{item.label}</option>;
    });

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add New Healthpass
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Healthpass Title"}
              placeholder={"Enter Healthpass Title"}
              state_name={"title"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            />

            {UI.box(10)}

            <View style={{ flex: 1 }}>
              <InputForm
                _this={_this}
                title={"Shortname Code"}
                placeholder={"Shortname Code"}
                state_name={"shortname_code"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={20}
              />
            </View>
            {UI.box(10)}

            <View style={{ flex: 1 }}>
              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Is Advocacy Healthpass?
              </span>

              {UI.box(10)}

              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"Yes"}
                  onClick={(value) => {
                    this.setState({
                      isAdvocacyHealthpass: !_this.state.isAdvocacyHealthpass,
                    });
                  }}
                  is_checked={_this.state.isAdvocacyHealthpass}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>

            <View style={{ flex: 1 }}>
              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Is this for Mind Health?
              </span>

              {UI.box(10)}

              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"Yes"}
                  onClick={(value) => {
                    this.setState({
                      isMindHealth: !_this.state.isMindHealth,
                    });
                  }}
                  is_checked={_this.state.isMindHealth}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Short Description"}
              placeholder={"Short Description"}
              state_name={"short_description"}
              style={{ flex: 1, flexBasis: "67%" }}
              isRequired={false}
              maxLength={UI.Constants.limits.description}
            />

            {UI.box(10)}

            <View style={{ flex: 1, flexBasis: "33%" }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Bundle with SKU"}
                // isRequired={false} 
                isMultiple={true}
                state_name={"bundled_sku"}
              >
                <option value="">Select SKU</option>
                {this.state.sku_bundle.map((item, index) => {
                  return item ? (
                    <option key={index} value={item._id}>{item.name}</option>
                  ) : (
                    <View></View>
                  );
                })}
              </Layout.SelectPicker>
            </View>
          </UI.Row>

          {UI.box(20)}



          <UI.Row _this={_this} breakpoint={1070}>

            <View style={{ flex: 1 }}>
              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Are you generating unique codes?
              </span>
              {UI.box(15)}

              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"Yes"}
                  onClick={(value) => {
                    this.setState({
                      isGenerateUniqueCode: false,
                    });
                  }}
                  is_checked={!_this.state.isGenerateUniqueCode}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text={
                    "No"
                  }
                  onClick={(value) => {
                    this.setState({
                      isGenerateUniqueCode: true,
                    });
                  }}
                  is_checked={_this.state.isGenerateUniqueCode}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>

            {/* <Layout.SelectPicker
              _this={_this}
              title={"Generate Code"}
              isRequired={true}
              state_name={"isGenerateUniqueCode"}
            >
              <option value="">Are you generating unique codes?</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </Layout.SelectPicker> */}
            {UI.box(10)}
            <Layout.DatePicker
              disabledPastDates={true}
              _this={_this}
              title={"Validity Start Date"}
              isRequired={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"startDate"}
              date_props={{}}
            />
            {UI.box(10)}
            <Layout.DatePicker
              disabledPastDates={true}
              _this={_this}
              title={"Validity End Date"}
              isRequired={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"endDate"}
              date_props={{}}

            />
          </UI.Row>

          {UI.box(20)}

          <UI.Row _this={_this} breakpoint={1070}>
            <View style={{ flex: 1 }}>

              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Can be used for 24 hrs?
              </span>

              {UI.box(10)}

              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"Yes"}
                  onClick={(value) => {
                    this.setState({
                      isUse24Hours: false,
                    });
                  }}
                  is_checked={!_this.state.isUse24Hours}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text={
                    "No"
                  }
                  onClick={(value) => {
                    this.setState({
                      isUse24Hours: true,
                    });
                  }}
                  is_checked={_this.state.isUse24Hours}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>
            {UI.box(10)}
            <View style={{ flex: 1 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Campaign"}
                isRequired={true}
                state_name={"campaign"}
              >
                <option value="">Select Campaign</option>
                {this.state.campaigns.map((item) => {
                  return item ? (
                    item.status === true &&
                    <option value={item._id}>{item.title}</option>
                  ) : (
                    <View></View>
                  );
                })}
              </Layout.SelectPicker>
            </View>
            {UI.box(10)}
            <View style={{ flex: 1 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Status"}
                isRequired={true}
                state_name={"status"}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Layout.SelectPicker>
            </View>
          </UI.Row>

          {UI.box(20)}
          <UI.Row _this={_this} breakpoint={1070}>
            <View style={{ flex: 1 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Start By"}
                isRequired={true}
                state_name={"startBy"}
              >
                <option value="">Select Option</option>
                <option value="purchaseDate">Purchase Date</option>
                <option value="claimDate">Claim Date</option>
                <option value="firstUse">First Use</option>
              </Layout.SelectPicker>
            </View>
            {UI.box(10)}
            <View style={{ flex: 1 }}>
              <InputForm
                _this={_this}
                title={"Validity Days"}
                placeholder={"Validity Days"}
                state_name={"validity_days"}
                style={{ flex: 1 }}
                maxLength={20}
              />
            </View>
            {UI.box(10)}
            <View style={{ flex: 1 }}>

              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Can be used on mWell doctors only?
              </span>

              {UI.box(10)}

              {/*  */}

              {/*  */}

              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"No"}
                  onClick={(value) => {
                    this.setState({
                      isForMwellDoctorsOnly: false,
                    });
                  }}
                  is_checked={!_this.state.isForMwellDoctorsOnly}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text={
                    "Yes"
                  }
                  onClick={(value) => {
                    this.setState({
                      isForMwellDoctorsOnly: true,
                    });
                  }}
                  is_checked={_this.state.isForMwellDoctorsOnly}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>
            {/* <View style={{flex:1}}></View>
            <View style={{flex:1}}></View> */}
          </UI.Row>
          {
            this.state.isUse24Hours &&

            <AddSchedule _this={this} />

          }

          {UI.box(20)}

          <Text style={{ ...styles.details_name, fontSize: '20px' }}>Service</Text>

          {UI.box(20)}

          <UI.Row _this={_this} breakpoint={1070}>
            <Layout.SelectPicker
              _this={_this}
              title={"Doctor Group"}
              isRequired={false}
              state_name={"doctor_group"}
            >
              <option value="">Select Doctor Group</option>
              {this.state.doctorGroup?.map((item) => {
                return item ? (
                  <option value={item._id}>{item.name}</option>
                ) : (
                  <View></View>
                );
              })}
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}>

              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Validity Service
              </span>
              {UI.box(10)}

              <UI.Row _this={_this} breakpoint={1070} style={{ gap: 5 }}>
                <View>
                  <div className="flex gap-5">
                    <Checkbox
                      _this={_this}
                      text={"Consult Now"}
                      onClick={(value) => {
                        this.setState({
                          validityServices: Object.assign({}, _this.state.validityServices, { cn: !_this.state.validityServices.cn }),
                        });
                        console.log(_this.state.validityServices)
                      }}
                      is_checked={_this.state.validityServices.cn}
                      color={"#2E2E2E"}
                    />
                    {/* {UI.box(10)} */}
                    <Checkbox
                      _this={_this}
                      text={
                        "Consult Later"
                      }
                      onClick={(value) => {
                        this.setState({
                          validityServices: Object.assign({}, _this.state.validityServices, { cl: !_this.state.validityServices.cl }),
                        });
                        console.log(_this.state.validityServices)
                      }}
                      is_checked={_this.state.validityServices.cl}
                      color={"#2E2E2E"}
                    />
                  </div>
                  {UI.box(5)}
                  {/* {
                    this.state.isAdvocacyHealthpass && <div className="flex gap-5 ">
                      <Checkbox
                        _this={_this}
                        text={"Advocacy CN"}
                        onClick={(value) => {
                          this.setState({
                            validityServices: Object.assign({}, _this.state.validityServices, {advocacyCN: !_this.state.validityServices.advocacyCN}),
                          });
                        }}
                        is_checked={_this.state.validityServices.advocacyCN}
                        color={"#2E2E2E"}
                      />
                      <Checkbox
                        _this={_this}
                        text={
                          "Advocacy CL"
                        }
                        onClick={(value) => {
                          this.setState({
                            validityServices: Object.assign({}, _this.state.validityServices, {advocacyCL: !_this.state.validityServices.advocacyCL}),
                          });
                        }}
                        is_checked={_this.state.validityServices.advocacyCL}
                        color={"#2E2E2E"}
                      />
                    </div>
                  } */}

                </View>
              </UI.Row>
            </View>

            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Max usage per supply"}
              placeholder={"Enter Max usage per supply"}
              state_name={"maxUsagePerPerson"}
              style={{ flex: 1 }}
              isRequired={true}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Price"}
              placeholder={"Enter Price"}
              state_name={"price"}
              style={{ flex: 1 }}
              maxLength={UI.Constants.limits.title}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Supply Stock"}
              placeholder={"Enter Supply Stock"}
              state_name={"quantity"}
              style={{ flex: 1 }}
              isRequired={true}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Discount"}
              placeholder={"Enter Percent Discount"}
              state_name={"discount"}
              style={{ flex: 1 }}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
          </UI.Row>

          {UI.box(20)}

          <UI.Row _this={_this} breakpoint={1070}>
            <View style={{ flex: 1 }}>
              <InputForm
                _this={_this}
                title={"Amount Cap (For PAGCOR only)"}
                placeholder={"Amount Cap"}
                state_name={"amountCap"}
                style={{ flex: 1 }}
                maxLength={20}
              />
            </View>

            {UI.box(20)}

            <View style={{ flex: 1 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Usability (For PAGCOR only)"}
                state_name={"usabilityVal"}
              >
                <option value="">Select usability</option>
                <option value="Single">Single</option>
                <option value="Multiple">Multiple</option>
              </Layout.SelectPicker>
            </View>

            {UI.box(20)}

            <View style={{ flex: 1 }}>
              <span className="font-semibold" style={{ color: UI.colors.primary }}>
                Transferrable (can be gifted)
              </span>
              {UI.box(10)}

              {UI.box(10)}
              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"Yes"}
                  onClick={(value) => {
                    console.log(this.state.isTransferrable)
                    this.setState({
                      isTransferrable: true,
                    });
                  }}
                  is_checked={_this.state.isTransferrable}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text={
                    "No"
                  }
                  onClick={(value) => {
                    console.log(this.state.isTransferrable)
                    this.setState({
                      isTransferrable: false,
                    });
                  }}
                  is_checked={!_this.state.isTransferrable}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>


            {UI.box(10)}

          </UI.Row>



          {UI.box(20)}

          <Text style={{ ...styles.details_name, fontSize: '20px' }}>Users</Text>

          {UI.box(20)}

          <Checkbox
            onClick={() => {
              this.setState(
                {
                  allowAllUsers: !this.state.allowAllUsers,
                },
                () => {
                  if (!this.state.allowAllUsers) {
                    if (this.state.users.length === 0) {
                      this.load_users();
                    }
                  }
                }
              );
            }}
            is_checked={this.state.allowAllUsers}
            text={"Allow all users"}
          />

          {UI.box(10)}

          {!this.state.allowAllUsers && (
            <UI.Row breakpoint={970} _this={_this}>
              <Layout.SelectPicker
                _this={_this}
                title={"Select Users"}
                // isRequired={true}
                state_name={"allowed_users"}
                isMultiple={true}
                onChange={(e) => { }}
                onInputChange={_.debounce((e) => this.load_users(e), 500)}
              >
                <option value="">Select Users</option>
                {Users}
              </Layout.SelectPicker>

              {UI.box(10)}

              <Layout.SelectPicker
                _this={_this}
                title={"User Group"}
                // isRequired={true}
                state_name={"user_group"}
              >
                <option value="">Select User Group</option>
                {this.state.userGroup?.map((item) => {
                  return item ? (
                    <option value={item._id}>{item.name}</option>
                  ) : (
                    <View></View>
                  );
                })}
              </Layout.SelectPicker>
            </UI.Row>
          )}

          {UI.box(10)}

          <UI.Row _this={_this} breakpoint={1070}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"ADD HEALTHPASS"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});