import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";
// import UI from "../../../shared/Components/UI/js";
import DropdownIcon from "../../../icons/Dropdown";
import ActionViewIcon from "../../../icons/ActionViewIcon";
import SearchIcon from "../../../icons/Search";
import Container from "../../../ComponentsFC/ui/Container";
import ContainerLabelPrimary from "../../../ComponentsFC/ui/ContainerLabelPrimary";
import CustomDatePicker from "../../../ComponentsFC/ui/forms/DatePicker";
import CustomFieldLabel from "../../../ComponentsFC/ui/forms/FieldLabel";
import CustomInputField from "../../../ComponentsFC/ui/forms/InputField";
import CustomSelectField from "../../../ComponentsFC/ui/forms/SelectField";
import DataTable from "../../DataTable";
// import toTitleCase from "../../../../utils/toTitleCase";
import DPReferralApi from "../../../api_services/Doctors-Portal/DPReferralApi";
// import { dpReferralsListApi as DUMMYdpReferralsListApi } from "../../../__fakeApi__/DPReferralsListApi";
// import FileDownload from "js-file-download";
import Loading from "../../../modules/_components/Loading";
import { isMobileScreen } from "../../../constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const referralTypeSelectOptions = [
  { type: "Teleconsult", value: "teleconsult" },
  { type: "Face-to-Face", value: "face-to-face" },
];

const onlyShowEntriesSelectOptions = [
  { type: "5 Entries", value: "5" },
  { type: "10 Entries", value: "10" },
  { type: "30 Entries", value: "30" },
];

const ReferralsList = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState();

  const [referralType, setReferralType] = useState();

  const [dateReferred, setDateReferred] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [appliedSearchQuery, setAppliedSearchQuery] = useState("");
  const location = useLocation();
  const isLoginEmailPath = location.pathname.includes("/email-login");

  const getReferralsList = useCallback(async () => {
    try {
      let urlParams = {};
      urlParams.sort = "createdAt";

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (dateReferred) {
        urlParams.date = dateReferred;
      }

      if (referralType) {
        urlParams.referral_type = referralType;
      }

      if (appliedSearchQuery) {
        urlParams.keyword = appliedSearchQuery;
      }

      setIsScreenLoading(true);
      setIsTableLoading(true);
      const apiResponse = await DPReferralApi.getReferralList(urlParams);
      // const apiResponse =
      //   await DUMMYdpReferralsListApi.getPatientReferralsDetails();
      setIsScreenLoading(false);
      setIsTableLoading(false);
      // console.log("GET REFERRAL LIST: ", apiResponse);
      setTotalRecords(apiResponse?.results?.total); // depending on return API JSON Structure
      setTableData(apiResponse?.data?.map((obj) => ({ ...obj, key: obj._id })));
      // //Dummy API
      // setTotalRecords(apiResponse?.d.results.total);
      // setTableData(
      //   apiResponse?.d.data?.map((obj) => ({ ...obj, key: obj._id }))
      // );
    } catch (err) {
      console.error(err);
    } finally {
      setIsScreenLoading(false);
      setIsTableLoading(false);
    }
  }, [
    currentPageNum,
    tableEntries,
    dateReferred,
    referralType,
    appliedSearchQuery,
  ]);

  useEffect(() => {
    getReferralsList();
  }, [getReferralsList]);

  const handleChangeDateReferred = (_, dateString) => {
    setDateReferred(dateString);
  };

  const handleChangeFilterByReferralType = (status) => {
    setReferralType(status);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    //Trigger getReferralsList if searchQuery inputField is empty
    if (!searchQuery) {
      setAppliedSearchQuery("");
    }
  }, [searchQuery]);

  const handleAppliedSearchQuery = () => {
    setAppliedSearchQuery(searchQuery);
  };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const tableColumnHeader = [
    {
      title: "Patient Name",
      dataIndex: "patientInfo",
      key: "patientInfo",
      render: (patientInfo) => {
        if (patientInfo) {
          return patientInfo[0]?.fullname;
          // return `${name[0]?.given?.join(" ")} ${name[0]?.family}`;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Date Referred",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        if (date) {
          return moment(date).utc().format("MM/DD/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "Referred by",
      dataIndex: "referredDoctorName",
      key: "referredDoctorName",
      render: (referredBy) => {
        if (referredBy) {
          return referredBy;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Specialty",
      dataIndex: "referredToSpecialty",
      key: "referredToSpecialty",
      render: (referredToSpecialty) => {
        if (referredToSpecialty) {
          return referredToSpecialty;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Referral Type",
      dataIndex: "type",
      key: "type",
      render: (referralType) => {
        if (referralType) {
          if (referralType === "teleconsult") return "Teleconsult";
          if (referralType === "face-to-face") return "F2F Appointment";

          return referralType;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action, rowData) => {
        return (
          <ActionViewIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push("/onboard/referral/details", { data: rowData })
            }
          />
        );
        // return (
        //   <ActionViewIcon
        //     style={{ cursor: "pointer" }}
        //     onClick={() => console.log("TRIGGERED ACTION", rowData)}
        //   />
        // );
      },
    },
  ];
  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={""}
        modal_opaque={false}
      />
      <Container>
        <ContainerLabelPrimary>Patient Referral</ContainerLabelPrimary>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
        <div className="lg:flex lg:flex-row gap-4">
          <div className="mb-4 lg:mb-0 ">
            <CustomFieldLabel>Date Referred</CustomFieldLabel>
            <div>
              <CustomDatePicker
                placeholder="Select Date"
                onChange={handleChangeDateReferred}
              />
            </div>
          </div>
          <div>
            <CustomFieldLabel>Referral Type</CustomFieldLabel>
            <CustomSelectField
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              selectoptions={referralTypeSelectOptions}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeFilterByReferralType}
            />
          </div>
        </div>
      </Container>

      <TableBox>
        <div className="sm:flex sm:flex-row lg:gap-4 p-5 mb-5 lg:mb-6">
          <div className="flex flex-row items-center pb-5 lg:pb-0">
            <FieldLabel>Only Show</FieldLabel>
            <CustomSelectField
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="30"
              style={{ width: isMobileScreen ? "100%" : 190, marginRight: 1 }}
              selectoptions={onlyShowEntriesSelectOptions}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            />
          </div>
          <CustomInputField
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={handleAppliedSearchQuery}
            style={{ width: !isMobileScreen && 300, marginLeft: "auto", height: (!isLoginEmailPath && isMobileScreen) && 50 }} //remove auto if you'll activate Export to CSV
          />
          {/* <StyledButton type="primary" onClick={generateDeletedDoctorsListCSV}>
            Export to CSV
          </StyledButton> */}
        </div>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default ReferralsList;

// CSS Styled Components ----------------------

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px !important;
  }

  // 4th COLUMN OF TABLE Specialty
  .ant-table-row .ant-table-cell:nth-child(4) {
    //white-space: pre;
  }

  @media (max-width: 1024px) {
    .ant-table-cell {
      font-size: 14px !important; 
      padding: 12px 12px 12px 12px;
    }
  }
`;


const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 20px; // 20px
  line-height: 20px; // 20px
  letter-spacing: 0;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

// const StyledButton = styled(Button)`
//   height: 60px;
//   border-radius: 12px;
//   border: none;
//   background-color: #0e4d94;
//   font-family: Poppins;
//   font-weight: 500;
//   font-size: 20px; // 20px
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   &:hover {
//     background-color: #43c8ff;
//   }
// `;

// const CellStyle = styled(Typography.Text)`
//   // Dynamically render style using props of this component
//   color: ${(props) =>
//     props.status === "Approved"
//       ? "#64C31E"
//       : props.status === "Pending"
//       ? "#00CBFF"
//       : props.status === "Disapproved"
//       ? "#CC0404"
//       : "#000000"};
// `;

// const StyledTooltip = styled(Tooltip)`
//   /* div.ant-tooltip-inner {
//     white-space: normal;
//   } */
// `;
