import React, { Component } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from "file-saver";
// import _ from "lodash";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  // Shadow,
  StyleSheet,
  Text,
  View,
  Wrap,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
// import { Checkbox } from "../_components/InputForm";
import Layout, { PreviewFile } from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success, CustomFailed } from "../_components/Modal";
import "./css.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import APIConstants from "../../shared/modules/APIConstants";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Tabs,
  // DatePicker,
  // Typography,
} from "antd";
// import styled from "styled-components";
import ServiceDoctorsApi from "../../api_services/doctors";
import ServiceLGUSApi from "../../api_services/lgu_partners";
import DoctorTaxTable from "./AdminViewDoctorRecordTaxTable";
import moment from "moment";

const context = "admin";
const Constants = new APIConstants();

export default class AdminViewDoctorRecord extends Component {
  constructor(props) {
    super(props);
    this.isOneLifeDoctorFeatureEnabled = false;

    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctor Accounts",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,

      total_consult: "",
      total_consult_now: "",
      total_consult_later: "",
      total_advocacy: "",

      gender: "",
      birthdate: "",
      medschool: [],
      qualification: "",
      specialty: "",
      language: "",
      hospitals: [],
      prc_number: "",
      prc_start_date: "",
      prc_end_date: "",

      name: "",
      email: "",
      phone: "",
      address: "",

      status: "",
      isMwellPlusStatus: "",
      tagging_consult_now: "",
      tagging_consult_later: "",

      picture: UI.DOCTOR_PROFILE,
      prcLicensePhoto: "",
      signature: "",
      mpc: "",
      moaPath: "",

      picture_filename: "",
      prcLicensePhoto_filename: "",
      signature_filename: "",
      moa_filename: "",
      mc_filename: "",
      mpc_filename: "",
      cor_filename: "",
      ptr_filename: "",
      s2_filename: "",

      bankname: "",
      bankaccountnumber: "",
      bankbranch: "",
      bankaccountname: "",
      mayaaccountnumber: "",

      picture_size: "",
      prcLicensePhoto_size: "",
      signature_size: "",
      moa_size: "",
      mc_size: "",
      mpc_size: "",
      cor_size: "",
      advocacies: [],
      doctorData: null,
      consultation_fee: "",
      chat_fee: "",
      isCopyLinkStep1: false,
      isCopyLinkStep2: false,

      remarks: "",
      isChangeStatus: false,
      isChangeTagging: false,
      isChangeAdvocacyDoctorStatus: false,
      isChangeContractedProfessionalFee: false,
      isChangemWellPlus: false,
      doctorType: "",
      contractedProfessionalFee: "",

      isChangeDoctorTypeRequest: false,
      doctorTypeRequestApproved: "",
      isPendingDoctorTypeRequest: "",
      requestedDoctorType: "",

      isChangeLguPartner: false,
      isLguPartner: false,
      isChangeExclusiveMember: false,
      isExclusiveToMemberships: false,
      isChangeOneLifeDoctor: false,
      isOneLifeDoctor: false,
      lgu: [],
      ptrNumber: "",
      s2Number: "",
      ptr: "",
      ptr_size: "",
      s2: "",
      s2_size: "",
      downloaded_ptr: "",
      downloaded_s2: "",

      bir_file: "",
      bir_files: [],

      memberships: [],
      doctor_memberships: [],
      isChangeDoctorMemberships: false,
    });
  }

  onCreate = () => {
    this.load_data();
    this.get_lgu();
    this.reFetchDoctorAccount();
  };

  reFetchDoctorAccount = () => {
    try {
      // const data = mem.get("edit_doctor");
      // const apiResponse = JSON.parse(data);
      // console.log(apiResponse);
      // setTableData(apiResponse.files);
      ServiceDoctorsApi.getDoctorBirFormsByAdmin(this.state.doctor_id).then(
        (res) => {
          mem.update("edit_doctor", "files", res.files);
          this.setState({ bir_files: res.files });
        }
      );
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  };

  upload_bir = async () => {
    let image = await UI.get_image(["pdf", "csv"]);
    this.auth_upload("bir_file", image.file);
  };

  upload_bir = async () => {
    let image = await UI.get_image(["pdf", "csv"]);
    this.auth_upload("bir_file", image.file);
  };

  auth_upload = async (state_name, file, callback) => {
    this.show_loading();
    UI.upload_image({
      url: "admin/upload",
      // file: file,
      params: {
        container: "doctors-onboarding",
        file: file,
        doctorId: this.state.doctor_id,
      },
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Process Failed"}
            description={"Upload Failed. Upload Error."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        const data = UI.extract_data(response);
        this.hide_loading();
        this.reFetchDoctorAccount();
        this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Upload Successful"}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
        this.setState(
          {
            [state_name]: data.url,
            icon_path: path,
          }
          // () => {
          //   if (callback) {
          //     callback(path);
          //   }
          //   this.process_file(state_name, file, path);
          // }
        );
      },
    });
  };

  get_lgu = () => {
    this.show_loading();

    ServiceLGUSApi.getAllLgu().then((res) => {
      this.hide_loading();
      if (res.status === 200) {
        let data = res.data;
        this.setState({ lgu: data });

        const data_doctor = mem.get("edit_doctor");
        const doctor = JSON.parse(data_doctor);
        // console.log(doctor.lguPartner);
        const selectedLgu = data.filter(
          (a) => a.userGroup === doctor.lguPartner?.userGroups[0]
        );
        // console.log(selectedLgu);
        this.setState({ selectedLgu: selectedLgu });

        // console.log(object);
      }
    });
  };

  load_data = async () => {
    const data = mem.get("edit_doctor");

    if (data) {
      const doctor = JSON.parse(data);
      // console.log("EDIT_DOCTOR DATA: ", doctor);
      let {
        picture,
        prcLicensePhoto,
        signature,
        moaPath,
        mpc,
        certificateOfRegistration,
        marketingConsentPath,
        officalReceipts,
        ptrNumber,
        s2Number,
        ptr,
        s2,
      } = doctor;

      if (doctor.provider) {
        if (doctor.provider.remarks) {
          UI.set_input_value("remarks", doctor.provider.remarks);
        }
      }

      let picture_filename = UI.container_filename(picture);

      let prcLicensePhoto_filename = UI.container_filename(prcLicensePhoto);
      let signature_filename = UI.container_filename(signature);
      let moa_filename = UI.container_filename(moaPath);
      let mc_filename = UI.container_filename(marketingConsentPath);
      let mpc_filename = UI.container_filename(mpc);
      let cor_filename = UI.container_filename(certificateOfRegistration);
      let ptr_filename = UI.container_filename(ptr);
      let s2_filename = UI.container_filename(s2);

      UI.method_queue(async () => {
        this.download_file(mpc, "downloaded_mpc");
        if (mpc) {
          mpc = await UI.download_image(mpc, "doctors-onboarding");
        } else {
          mpc = "#";
        }

        let size = await UI.get_file_size_from_url(mpc);
        this.setState({
          mpc,
          mpc_size: size,
        });
      });

      UI.method_queue(async () => {
        this.download_file(certificateOfRegistration, "downloaded_cor");
        if (certificateOfRegistration) {
          certificateOfRegistration = await UI.download_image(
            certificateOfRegistration,
            "doctors-onboarding"
          );
          // console.log(certificateOfRegistration);
        } else {
          certificateOfRegistration = "#";
        }

        let size = await UI.get_file_size_from_url(certificateOfRegistration);
        this.setState({
          certificateOfRegistration,
          cor_size: size,
        });
      });

      UI.method_queue(async () => {
        picture = await UI.download_image(picture, "doctors-onboarding");
        let size = await UI.get_file_size_from_url(picture);
        this.setState({
          picture,
          picture_size: size,
        });
      });
      UI.method_queue(async () => {
        this.download_file(prcLicensePhoto, "downloaded_prc_license_photo");
        if (prcLicensePhoto) {
          prcLicensePhoto = await UI.download_image(
            prcLicensePhoto,
            "doctors-onboarding"
          );
        } else {
          prcLicensePhoto = "#";
        }
        let size = await UI.get_file_size_from_url(prcLicensePhoto);
        this.setState({
          prcLicensePhoto,
          prcLicensePhoto_size: size,
        });
      });
      UI.method_queue(async () => {
        this.download_file(signature, "downloaded_signature");
        if (signature) {
          signature = await UI.download_image(signature, "doctors-onboarding");
        } else {
          signature = "#";
        }
        let size = await UI.get_file_size_from_url(signature);
        this.setState({
          signature,
          signature_size: size,
        });
      });

      // UI.method_queue(async () => {
      //   this.download_file(officalReceipts, "downloaded_officalReceipts");
      // });

      UI.method_queue(async () => {
        const membershipsResult = await ServiceDoctorsApi.getMemberships();

        this.setState({
          memberships: membershipsResult.memberships.items.map((item) => ({
            id: item.id,
            name: item.organization.name,
          })),
        });

        UI.method_queue(async () => {
          const membershipsResult =
            await ServiceDoctorsApi.getDoctorMemberships(doctor._id);

          UI.set_multiple_select_value(
            "doctor_memberships",
            membershipsResult.memberships.items.map((item) => item.id)
          );
        });
      });

      if (officalReceipts?.length > 0) {
        UI.method_queue(async () => {
          officalReceipts?.map((or, key) => {
            return this.download_file(
              or.path,
              "downloaded_officalReceipts" + key
            );
          });
        });
      }

      UI.method_queue(async () => {
        this.download_file(moaPath, "downloaded_moaPath");
        if (moaPath) {
          moaPath = await UI.download_image(moaPath, "doctors-onboarding");
        } else {
          moaPath = "#";
        }
        let size = await UI.get_file_size_from_url(moaPath);
        this.setState({
          moaPath,
          moa_size: size,
        });
      });

      // let isMwellPlusChange = "";

      UI.method_queue(async () => {
        this.download_file(marketingConsentPath, "downloaded_mcPath");
        if (marketingConsentPath) {
          marketingConsentPath = await UI.download_image(
            marketingConsentPath,
            "doctors-onboarding"
          );
        } else {
          marketingConsentPath = "#";
        }
        let size = await UI.get_file_size_from_url(marketingConsentPath);
        this.setState({
          marketingConsentPath,
          mc_size: size,
        });
      });

      UI.method_queue(async () => {
        this.download_file(ptr, "downloaded_ptr");
        if (ptr) {
          ptr = await UI.download_image(ptr, "doctors-onboarding");
        } else {
          ptr = "#";
        }

        let size = await UI.get_file_size_from_url(ptr);
        this.setState({
          ptr,
          ptr_size: size,
        });
      });

      UI.method_queue(async () => {
        this.download_file(s2, "downloaded_s2");
        if (s2) {
          s2 = await UI.download_image(s2, "doctors-onboarding");
        } else {
          s2 = "#";
        }

        let size = await UI.get_file_size_from_url(s2);
        this.setState({
          s2,
          s2_size: size,
        });
      });

      // let isMwellPlus = "";
      let status_value = "";
      let tagging_consult_now_value = "";
      let tagging_consult_later_value = "";
      let tagging_registration_status_step1 = "";
      let tagging_registration_status_step2 = "";
      let tagging_registration_status_step3 = "";

      if (doctor.conferenceMode === "ITS") {
        tagging_consult_now_value = "1";
      } else if (doctor.conferenceMode === "Carespan") {
        tagging_consult_now_value = "2";
      }
      if (doctor.conferenceModeCL === "ITS") {
        tagging_consult_later_value = "3";
      } else if (doctor.conferenceModeCL === "Carespan") {
        tagging_consult_later_value = "4";
      }

      if (
        doctor.provider.isAdvocacyOnboardingApproved?.step1?.status ===
          "Approved" ||
        doctor.provider.isAdvocacyOnboardingApproved?.step1?.status ===
          "Approve"
      ) {
        tagging_registration_status_step1 = "1";
      } else if (
        doctor.provider.isAdvocacyOnboardingApproved?.step1?.status ===
        "Disapproved"
      ) {
        tagging_registration_status_step1 = "2";
      }

      if (
        doctor.provider.isAdvocacyOnboardingApproved?.step2?.status ===
          "Approved" ||
        doctor.provider.isAdvocacyOnboardingApproved?.step2?.status ===
          "Approve"
      ) {
        tagging_registration_status_step2 = "2";
      } else if (
        doctor.provider.isAdvocacyOnboardingApproved?.step2?.status ===
        "Disapproved"
      ) {
        tagging_registration_status_step2 = "3";
      }

      if (
        doctor.provider.isAdvocacyOnboardingApproved?.step3?.status ===
          "Approved" ||
        doctor.provider.isAdvocacyOnboardingApproved?.step3?.status ===
          "Approve"
      ) {
        tagging_registration_status_step3 = "1";
      } else if (
        doctor.provider.isAdvocacyOnboardingApproved?.step3?.status ===
        "Disapproved"
      ) {
        tagging_registration_status_step3 = "2";
      }

      if (doctor.provider.isDoctorsOnboardingApproved === "Approved") {
        status_value = "true";
      } else if (
        doctor.provider.isDoctorsOnboardingApproved === "Disapproved"
      ) {
        status_value = "false";
      } else {
        status_value = "";
      }

      // if (doctor.conferenceMode === "ITS") {
      //   tagging_consult_now_value = "1";
      // }

      // LOAD DATA LOGIC OF SWITCH DOCTOR TYPE
      let hasPendingDoctorTypeRequest = "";
      if (
        doctor?.requestDoctorType?.consulting?.isRequest === true ||
        doctor?.requestDoctorType?.contracted?.isRequest === true
      ) {
        hasPendingDoctorTypeRequest = true;
      } else {
        hasPendingDoctorTypeRequest = false;
      }

      let hasRequestedDoctorType = "";
      if (doctor?.requestDoctorType?.consulting?.isRequest === true) {
        hasRequestedDoctorType = "consulting";
      }
      if (doctor?.requestDoctorType?.contracted?.isRequest === true) {
        hasRequestedDoctorType = "contracted";
      }

      let isDoctorTypeRequestApproved = "";
      if (
        doctor?.requestDoctorType?.consulting?.isApproved === true ||
        doctor?.requestDoctorType?.contracted?.isApproved === true
      ) {
        isDoctorTypeRequestApproved = true;
      } else if (
        doctor?.requestDoctorType?.consulting?.isRequest === undefined ||
        doctor?.requestDoctorType?.contracted?.isRequest === undefined
      ) {
        isDoctorTypeRequestApproved = "noPendingRequest";
      } else {
        isDoctorTypeRequestApproved = false;
      }

      UI.set_input_value("consultation_fee", doctor.pricePerConsultation ?? "");
      UI.set_input_value("chat_fee", doctor.pricePerChatConsultation ?? "");
      UI.set_select_value("doctorType", doctor.isMwellPlus);
      UI.set_select_value(
        "doctorTypeRequestApproved",
        isDoctorTypeRequestApproved ?? ""
      );
      UI.set_select_value("status", status_value);
      UI.set_select_value("tagging_consult_now", tagging_consult_now_value);
      UI.set_select_value("tagging_consult_later", tagging_consult_later_value);
      UI.set_select_value(
        "advocacy_doctor_status_step1",
        tagging_registration_status_step1
      );
      UI.set_select_value(
        "advocacy_doctor_status_step2",
        tagging_registration_status_step2
      );
      UI.set_select_value(
        "advocacy_doctor_status_step3",
        tagging_registration_status_step3
      );

      let phone = "";
      if (doctor.telecom.length) {
        phone = "+63 " + UI.DataHelper.carespan.get_phone(doctor.telecom);
      } else {
        phone = "+63 " + doctor.provider?.phone;
      }

      this.setState({
        advocacies: doctor.advocacies,
        mpc_filename,
        cor_filename,
        picture_filename,
        prcLicensePhoto_filename,
        signature_filename,
        moa_filename,
        mc_filename,
        gender: UI.DataHelper.title(doctor.gender),
        birthdate: UI.DataHelper.carespan.get_date(doctor.birthDate, true),
        medschool: doctor.medicalAssociations,
        qualification: UI.DataHelper.carespan.get_date(
          doctor.qualificationDate
        ),
        specialty: UI.DataHelper.carespan.get_specialties(
          doctor.fieldSpecialties
        ),
        language: UI.DataHelper.carespan.get_languages(doctor.languange),
        address: UI.DataHelper.carespan.get_address(doctor.address),
        hospitals: doctor.hospitals,
        tagging:
          this.state.tagging === "1"
            ? "ITS"
            : this.state.tagging === "2"
            ? "Carespan"
            : this.state.tagging === "3"
            ? "ITS"
            : this.state.tagging === "4"
            ? "Carespan"
            : "",
        prc_number: doctor.prcLicenseNumber,
        prc_start_date: UI.DataHelper.carespan.get_date(
          doctor.prcLicenseStartDate
        ),
        prc_end_date: UI.DataHelper.carespan.get_date(doctor.prcLicenseEndDate),

        name: UI.DataHelper.carespan.get_name(doctor.name),
        email: doctor.provider.email,
        phone: phone,
        bankname: doctor.financialInformation?.bankName ?? "",
        bankaccountname: doctor.financialInformation?.bankAccountName ?? "",
        bankaccountnumber: doctor.financialInformation?.bankAccountNumber ?? "",
        bankbranch: doctor.financialInformation?.bankBranch ?? "",
        mayaaccountnumber: doctor.financialInformation?.mayaAccountNumber ?? "",
        id: doctor.provider._id,
        doctor_id: doctor._id,
        doctorData: doctor,
        isMwellPlusStatus: doctor.isMwellPlus,
        isPendingDoctorTypeRequest: hasPendingDoctorTypeRequest ?? "",
        requestedDoctorType: hasRequestedDoctorType ?? "",
        isLguPartner: doctor.lguPartner
          ? doctor.lguPartner?.isLguPartner ?? doctor.lguPartner?.isLguPartner
          : false,
        isExclusiveToMemberships: doctor.isExclusiveToMemberships,
        isOneLifeDoctor: doctor.isOneLifeDoctor,
        officalReceipts: officalReceipts,
        ptrNumber,
        s2Number,
        ptr_filename,
        s2_filename,
      });
      this.consultations(doctor._id);
    }
  };

  consultations = async (doctor_id) => {
    UI.webRequest({
      method: "get",
      url: "admin/doctors/stats/" + doctor_id,
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          total_consult: data.totalConsultations,
          total_consult_now: data.totalConsultNow,
          total_consult_later: data.totalConsultLater,
          total_advocacy: data.totalAdvocacy,
        });
      },
      onFinish: async () => {},
    });
  };

  update_mwell_plus = async (id, val) => {
    UI.webRequest({
      method: "put",
      url: "admin/doctors/set_mwellplus/" + id,
      params: { isMwellPlus: val },
      onFail: (err) => {},
      onSuccess: (response) => {
        // const data = UI.extract_data(response);
        this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Doctor successfully Updated."}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
              // const test_data = mem.update("edit_doctor", "ismWellPlus", val);
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  update_doctor_type_request = async (
    id,
    isApprovedVal,
    requestedDoctorType
  ) => {
    // console.log(
    //   "UPDATE DOCTOR TYPE FUNCTION: ",
    //   id,
    //   isApprovedVal,
    //   typeof isApprovedVal,
    //   requestedDoctorType
    // );
    let convertedIsApprovedVal = "";

    if (isApprovedVal === "true") {
      convertedIsApprovedVal = true;
    }
    if (isApprovedVal === "false") {
      convertedIsApprovedVal = false;
    }
    // Block API CALL
    if (isApprovedVal === "") return;

    UI.webRequest({
      method: "put",
      url: "admin/doctors/approve-doctor-type/" + id,
      params: {
        isApproved: convertedIsApprovedVal,
        doctorType: requestedDoctorType,
      },
      onFail: (err) => {
        // console.log("Doctor Type Request Failed:", err);

        let errMsg = "";

        if (err?.status === 404) {
          errMsg = "404. The server cannot find the requested resource.";
        } else if (err?.status === 401) {
          errMsg = "401. Unauthorized.";
        } else if (err?.status === 500) {
          errMsg = "500. Internal Server Error.";
        } else if (err?.status === 503 || err === undefined) {
          errMsg = "503. Service Unavailable.";
        } else {
          errMsg = err?.statusText;
        }

        this.show_modal(
          <Failed
            header_title={"Error"}
            description={errMsg}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        );
      },
      onSuccess: (response) => {
        // const data = UI.extract_data(response);
        // console.log("Doctor Type Request Success: ", response);
        const doctorTypeRequestDenied =
          response?.data?.d?.doctor?.requestDoctorType?.contracted
            ?.isApproved === false &&
          response?.data?.d?.doctor?.requestDoctorType?.consulting
            ?.isApproved === false;

        const doctorTypeRequestApproved =
          response?.data?.d?.doctor?.requestDoctorType?.contracted
            ?.isApproved === true ||
          response?.data?.d?.doctor?.requestDoctorType?.consulting
            ?.isApproved === true;

        if (doctorTypeRequestDenied) {
          this.show_modal(
            <CustomFailed
              title={"PROCESS FAILED"}
              description_title={""}
              description={"Doctor type application denied"}
              _this={this}
              imageBanner={UI.PROCESS_FAILED_BANNER}
              buttonText={"Continue"}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        } else if (doctorTypeRequestApproved) {
          this.show_modal(
            <Success
              title={"SUCCESS!"}
              descriptionCenter={"Doctor type application approved."}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
                // const test_data = mem.update("edit_doctor", "ismWellPlus", val);
              }}
            />
          );
        } else {
          this.show_modal(
            <Success
              title={"SUCCESS!"}
              descriptionCenter={"Doctor successfully Updated."}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
                // const test_data = mem.update("edit_doctor", "ismWellPlus", val);
              }}
            />
          );
        }
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  updateTaggingDoctor = (doctor_id, taggingValue) => {
    UI.webRequest({
      method: "put",
      url: "admin/doctors/tagged-doctor/" + doctor_id,
      params: {
        conferenceMode: taggingValue,
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        const status =
          this.state.tagging === "1"
            ? "ITS"
            : this.state.tagging === "2"
            ? "Carespan"
            : this.state.tagging === "3"
            ? "ITS CL"
            : this.state.tagging === "4"
            ? "Carespan CL"
            : "";
        this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Doctor is successfully " + status + "."}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  validateFee = async (fee, feeType) => {
    if (!fee) {
      await UI.error_form("contractedProfessionalFee", Constants[`${feeType}_validation`]);
      return true;
    }

    const feeValue = parseInt(fee, 10);

    if (feeValue < (feeType === 'consultation_fee' ? Constants.consultation_fee : Constants.chat_fee)) {
      await UI.error_form("contractedProfessionalFee", Constants[`${feeType}_validation`]);
      return true;
    }
  
    if (feeValue % 50 !== 0) {
      await UI.error_form("contractedProfessionalFee", Constants[`${feeType}_validation`]);
      return true;
    }
  
    return false;
  }

  submit = async () => {
    UI.clear_errors();
    //no merchant yet

    const {
      status,
      id,
      remarks,
      doctor_id,
      tagging_consult_later,
      tagging_consult_now,
      // isMwellPlusChange,
      // advocacy_doctor_status_step1,
      // advocacy_doctor_status_step2,
      // advocacy_doctor_status_step3,
      // isChangeAdvocacyDoctorStatus,
      doctorData,
      isChangeStep1,
      isChangeStep2,
      isChangeStep3,
      advocacy_doctor_status_step1,
      advocacy_doctor_status_step2,
      advocacy_doctor_status_step3,
      isChangeContractedProfessionalFee,
    } = this.state; // can be "true" or "false"
    // if (!status) {
    //   await UI.error_form("status", "Status is required.");
    // }

    //when a doctor is being disapproved
    // if (status !== "true") {
    //   if (!remarks) {
    //     await UI.error_form("remarks", "Remarks is required.");
    //   }
    // }

    // const error_count = this.state.error_count;
    // if (error_count > 0) {
    //   return;
    // }

    // const data = mem.get("edit_doctor");
    // const doctor = JSON.parse(data);
    let taggingValue = "";

    if (this.state?.isChangeTagging) {
      if (tagging_consult_now === "1") {
        taggingValue = "ITS";
        this.updateTaggingDoctor(doctor_id, taggingValue);
      } else if (tagging_consult_now === "2") {
        taggingValue = "Carespan";
        this.updateTaggingDoctor(doctor_id, taggingValue);
      }

      if (tagging_consult_later === "3") {
        taggingValue = "ITS CL";
        this.updateTaggingDoctor(doctor_id, taggingValue);
      } else if (tagging_consult_later === "4") {
        taggingValue = "Carespan CL";
        this.updateTaggingDoctor(doctor_id, taggingValue);
      }
    }

    if (this.state?.isChangemWellPlus) {
      this.show_loading();
      this.setState({ isChangemWellPlus: false });
      this.update_mwell_plus(doctor_id, !doctorData.isMwellPlus);
    }

    if (this.state?.isChangeDoctorTypeRequest) {
      this.show_loading();
      this.setState({ isChangeDoctorTypeRequest: false });
      this.update_doctor_type_request(
        doctor_id,
        this.state.doctorTypeRequestApproved,
        this.state.requestedDoctorType
      );
    }

    if (this.state?.isChangeStatus) {
      console.log(this.state?.isChangeStatus);
      this.show_loading("", "Please wait, saving changes.... ");

      this.setState({ isChangeStatus: false });

      let params = {};

      params.approved = status === "Pending" ? "Disapproved" : status;
      params.remarks = status === "true" ? "" : remarks;

      ServiceDoctorsApi.updateDoctorRegistrationStatus(id, params).then(
        (res) => {
          this.hide_loading();
          // setIsLoading(false);
          if (res.status === 200) {
            // let data = res.data;
            // const status = this.state.status === "false" ? "disapproved" : "approved";
            this.show_modal(
              <Success
                title={"Success!"}
                descriptionCenter={"Doctor is successfully " + status + "."}
                onDismiss={() => {
                  this.hide_modal();
                  UI.goBack();
                }}
              />
            );
          }
        }
      );

      return;

      // UI.webRequest({
      //   method: "put",
      //   url: "admin/doctors/approved/" + id,
      //   params: {
      //     approved: status === "true" ? "Approved" : "Disapproved",
      //     remarks: status === "true" ? "" : remarks,
      //   },
      //   onFail: async (err) => {
      //     const data = err.data;
      //     const error_message = data.error;
      //     this.show_modal(
      //       <Failed
      //         title={"Failed"}
      //         description={error_message}
      //         onDismiss={() => {
      //           this.hide_modal();
      //         }}
      //       />
      //     );
      //   },
      //   onSuccess: async (response) => {
      //     const status =
      //       this.state.status === "false" ? "disapproved" : "approved";
      //     this.show_modal(
      //       <Success
      //         title={"Success!"}
      //         descriptionCenter={"Doctor is successfully " + status + "."}
      //         onDismiss={() => {
      //           this.hide_modal();
      //           UI.goBack();
      //         }}
      //       />
      //     );
      //   },
      //   onFinish: () => {
      //     this.hide_loading();
      //   },
      // });
    }

    if (this.state?.isChangeAdvocacyDoctorStatus) {
      this.show_loading();

      this.setState({ isChangeAdvocacyDoctorStatus: false });
      let stepPayload = {};
      if (isChangeStep1) {
        this.setState({ isChangeStep1: false });

        stepPayload = {
          stage: 1,
          approved:
            advocacy_doctor_status_step1 === "1" ? "Approved" : "Disapproved",
          remarks: advocacy_doctor_status_step1 === "2" ? remarks : "",
          url: `${Constants.WEB_HOST_NAME_URL}/advocacy-doctor-register?step=2&advocacy=${doctorData.advocacies[0]?._id}&practitionerID=${doctorData.id}`,
        };
      } else if (isChangeStep2) {
        this.setState({ isChangeStep2: false });

        stepPayload = {
          stage: 2,
          url: `${Constants.WEB_HOST_NAME_URL}/advocacy-doctor-register?step=3&advocacy=${doctorData.advocacies[0]?._id}&practitionerID=${doctorData.id}`,
          approved:
            advocacy_doctor_status_step2 === "2" ? "Approved" : "Disapproved",
          remarks: advocacy_doctor_status_step2 === "3" ? remarks : "",
        };
      } else if (isChangeStep3) {
        this.setState({ isChangeStep2: false });

        stepPayload = {
          stage: 3,
          approved:
            advocacy_doctor_status_step3 === "1" ? "Approved" : "Disapproved",
          remarks: advocacy_doctor_status_step3 === "2" ? remarks : "",
        };
      }

      UI.webRequest({
        method: "put",
        url: "admin/doctors/approved/" + id,
        params: {
          ...stepPayload,
        },
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={`Doctor is successfully ${
                advocacy_doctor_status_step1 === "2" ||
                advocacy_doctor_status_step2 === "3" ||
                advocacy_doctor_status_step3 === "2"
                  ? "disapproved"
                  : "approved"
              } `}
              descriptionBottom={
                !isChangeStep3 &&
                ((isChangeStep1 && advocacy_doctor_status_step1 === "1") ||
                  (isChangeStep2 && advocacy_doctor_status_step2 === "2")) ? (
                  <View>
                    <CopyToClipboard
                      text={`${
                        Constants.WEB_HOST_NAME_URL
                      }/advocacy-doctor-register?step=${
                        isChangeStep1 ? 2 : isChangeStep2 ? 3 : ""
                      }&advocacy=${
                        doctorData.advocacies[0]?._id
                      }&practitionerID=${doctorData.id}`}
                    >
                      <button
                        style={{
                          textDecoration: "underline",
                          fontWeight: 600,
                          borderRadius: "7px",
                          padding: "5px 8px",
                          fontSize: "14px",
                          flex: 1,
                          color: "#0056A",
                        }}
                        href="#"
                      >
                        Please click me to copy the link for step{" "}
                        {isChangeStep1 ? 2 : isChangeStep2 ? 3 : ""}
                      </button>
                    </CopyToClipboard>
                  </View>
                ) : (
                  ""
                )
              }
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
    }

    if (isChangeContractedProfessionalFee) {

      const consultationFeeError = await this.validateFee(this.state.consultation_fee, "consultation_fee");
      const chatFeeError = await this.validateFee(this.state.chat_fee, "chat_fee");

    if (consultationFeeError || chatFeeError) {
    return;
  }

      const error_count = this.state.error_count;
      if (error_count > 0) {
        return;
      }

      this.show_loading();

      UI.webRequest({
        method: "put",
        url: "admin/doctors/update-doctor-price/" + doctor_id,
        params: {
          pricePerConsultation: parseInt(this.state.consultation_fee),
          pricePerChatConsultation: parseInt(this.state.chat_fee)
        },
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={"Successfully updated the consultation fee."}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
    }

    this.hide_loading();

    if (this.state.isChangeLguPartner) {
      if (this.state.isLguPartner) {
        if (!this.state.usergroup) {
          await UI.error_form("usergroup", "Select Location.");
        }
      }

      const error_count = this.state.error_count;
      if (error_count > 0) {
        return;
      }

      this.show_loading();

      UI.webRequest({
        method: "put",
        url: "admin/lgu/member",
        params: {
          doctorsGroupId: this.state.usergroup,
          doctorProviderId: this.state.id,
          isLguPartner: this.state.isLguPartner,
        },
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={"Successfully updated the LGU partners."}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
    }

    if (this.state.isChangeExclusiveMember) {
      this.show_loading();

      UI.webRequest({
        method: "put",
        url: `admin/doctors/${doctor_id}`,
        params: {
          isExclusiveToMemberships: this.state.isExclusiveToMemberships,
        },
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={
                "Successfully updated doctor's membership exclusivity status"
              }
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });

      this.hide_loading();
    }

    if (this.state.isChangeOneLifeDoctor) {
      this.show_loading();

      UI.webRequest({
        method: "put",
        url: `admin/doctors/${doctor_id}`,
        params: {
          isOneLifeDoctor: this.state.isOneLifeDoctor,
        },
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={
                "Successfully updated doctor's one life membership status"
              }
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });

      this.hide_loading();
    }

    if (this.state.isChangeDoctorMemberships) {
      this.show_loading();

      UI.webRequest({
        method: "post",
        url: `admin/doctors/${doctor_id}/memberships`,
        params: {
          tagIds: this.state.doctor_memberships,
        },
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={"Successfully updated doctor's memberships"}
              onDismiss={() => {
                this.hide_modal();
                UI.goBack();
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });

      this.hide_loading();
    }
  };

  download_file = async (path, state) => {
    if (path) {
      const ext = await UI.get_file_extension(path);
      const image_link = await UI.download_image(path, "doctors-onboarding");
      this.setState({
        [state]: {
          url: path?.includes("doctors/") ? image_link : path,
          extension: ext,
          filename: UI.container_filename(path),
        },
      });
    }
  };

  render() {
    const _this = this;
    const { width } = _this.state;

    const {
      total_consult,
      total_consult_now,
      total_consult_later,
      total_advocacy,
      gender,
      birthdate,
      // medschool,
      qualification,
      specialty,
      language,
      hospitals,
      prc_number,
      prc_start_date,
      prc_end_date,
      name,
      email,
      address,
      phone,
      picture,
      prcLicensePhoto,
      signature,
      prcLicensePhoto_filename,
      signature_filename,
      prcLicensePhoto_size,
      signature_size,
      advocacies,

      moa_filename,
      mc_filename,
      moa_size,
      mc_size,
      moaPath,
      marketingConsentPath,

      mpc_filename,
      mpc_size,
      mpc,

      cor_filename,
      cor_size,
      certificateOfRegistration,

      // doctorType,

      bankname,
      bankaccountname,
      bankaccountnumber,
      bankbranch,
      mayaaccountnumber,

      downloaded_prc_license_photo,
      downloaded_signature,
      downloaded_moaPath,
      downloaded_mcPath,
      downloaded_mpc,
      downloaded_cor,
      doctorData,
      picture_filename,
      ptrNumber,
      s2Number,
      ptr,
      s2,
      ptr_filename,
      s2_filename,
      downloaded_ptr,
      downloaded_s2,
      ptr_size,
      s2_size,
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <View style={styles.fl_ai}>
            <Text
              style={{
                ...styles.box_header_text,
                flex: 1,
                marginBottom: 20,
              }}
            >
              Doctor's Information
            </Text>
          </View>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 120, width: 120, borderRadius: 5 }}
                  src={picture_filename ? picture : UI.DEFAULT_AVATAR_DOCTOR}
                  alt="N/A"
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{name}</Text>
                  <Text style={styles.patient_details}>{email}</Text>
                  <Text style={styles.patient_details}>{phone}</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              {/* <UI.Row
                breakpoint={980}
                breakpoint_2={720}
                breakpoint_3={590}
                _this={_this}
              >
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_GENDER}
                    name={"Gender"}
                    value={gender}
                  />
                  <UserDetail
                    icon={UI.DETAILS_BIRTHDATE}
                    name={"Birthdate"}
                    value={birthdate}
                  />
                  <UserDetail
                    icon={UI.DETAIL_QUALIFICATION}
                    name={"Qualification"}
                    value={qualification}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_PRC}
                    name={"PRC Number"}
                    value={prc_number}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"PRC Start Date"}
                    value={prc_start_date}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"PRC End Date"}
                    value={prc_end_date}
                  />
                </View>
              </UI.Row>
              <UserDetail
                icon={UI.DETAILS_SPECIALIZATION}
                name={"Specialty"}
                value={specialty}
              /> */}
              <Row>
                <Col
                  xs={24}
                  sm={8}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img height={60} width={60} src={UI.TELEMED_STATUS} alt="" />
                  <div className="ml-5">
                    <h3
                      style={{
                        color:
                          doctorData?.provider.isDoctorsOnboardingApproved ===
                          "Approved"
                            ? "#1ac200"
                            : doctorData?.provider
                                .isDoctorsOnboardingApproved === "Pending"
                            ? "#e6b820"
                            : doctorData?.provider
                                .isDoctorsOnboardingApproved === "Disapproved"
                            ? "#e51919"
                            : "",
                      }}
                      className="text-2xl mb-1 font-bold"
                    >
                      {doctorData?.provider.isDoctorsOnboardingApproved
                        ? doctorData?.provider.isDoctorsOnboardingApproved
                        : "N/A"}
                    </h3>
                    <span className="font-semibold">Telemed Status</span>
                  </div>
                </Col>
                <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                  <img height={60} width={60} src={UI.ADVOCACY_STATUS} alt="" />
                  <div className="ml-5">
                    <h3
                      style={{ color: "#07cf7f" }}
                      className="text-green-800 text-2xl mb-1 font-bold"
                    >
                      {doctorData?.provider?.isAdvocacyOnboardingApproved?.step1
                        .status === "Approved" &&
                      doctorData?.provider?.isAdvocacyOnboardingApproved?.step2
                        .status === "Aprroved" &&
                      doctorData?.provider?.isAdvocacyOnboardingApproved?.step3
                        .status === "Aprroved"
                        ? "Ready to Upload"
                        : doctorData?.provider?.isAdvocacyOnboardingApproved
                            ?.step1.status === "Pending"
                        ? "N/A"
                        : doctorData?.provider?.isAdvocacyOnboardingApproved
                            ?.step2.status === "Aprroved"
                        ? "Ready to Upload"
                        : doctorData?.provider?.isAdvocacyOnboardingApproved
                            ?.step2.status === ""
                        ? "N/A"
                        : doctorData?.provider?.isAdvocacyOnboardingApproved
                            ?.step3.status === "Approved"
                        ? "Ready to Upload"
                        : doctorData?.provider?.isAdvocacyOnboardingApproved
                            ?.step3.status === "Disapproved"
                        ? "Disapproved"
                        : "N/A"}
                      {/* {doctorData?.provider?.isAdvocacyOnboardingApproved.step3.status === "Approved" ? "Ready to Upload" : "N/A"} */}
                    </h3>
                    <span className="font-semibold">Advocacy Status</span>
                  </div>
                </Col>
                <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                  <img height={60} width={60} src={UI.DOCTOR_TYPE} alt="" />
                  <div className="ml-5">
                    <h3
                      style={{ color: "#4f93ed" }}
                      className="text-2xl mb-1 font-bold"
                    >
                      {this.state.isMwellPlusStatus
                        ? "Contracted"
                        : "Consulting"}
                    </h3>
                    <span className="font-semibold">Doctor Type</span>
                  </div>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 15 }}>
                <Col
                  span={24}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img height={60} width={60} src={UI.NO_OF_CONSULTS} alt="" />
                  <div className="ml-5 flex items-center content">
                    <div className="pr-10">
                      <h6
                        style={{ color: UI.colors.aqua }}
                        className="text-2xl mb-1 font-bold"
                      >
                        {total_consult ? total_consult : "-"}
                      </h6>
                      <span className="font-semibold">No of Consults</span>
                    </div>
                    <div className="pr-10">
                      <h6
                        style={{ color: UI.colors.aqua }}
                        className="text-2xl mb-1 font-bold"
                      >
                        {total_consult_now ? total_consult_now : "-"}
                      </h6>
                      <span className="font-semibold">Consult Now</span>
                    </div>
                    <div className="pr-10">
                      <h6
                        style={{ color: UI.colors.aqua }}
                        className="text-2xl mb-1 font-bold"
                      >
                        {total_consult_later ? total_consult_later : "-"}
                      </h6>
                      <span className="font-semibold">Consult Later</span>
                    </div>
                    <div className="pr-10">
                      <h6
                        style={{ color: UI.colors.aqua }}
                        className="text-2xl mb-1 font-bold"
                      >
                        {total_advocacy ? total_advocacy : "-"}
                      </h6>
                      <span className="font-semibold">Advocacy</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, marginTop: 20 }}>
          <Tabs defaultActiveKey="1">
            <StyledTabsTabPane
              tab="Information"
              key="1"
              className="overflow-auto"
            >
              <Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
                <Text style={{ ...styles.box_header_text, flex: 1 }}>
                  Additional Doctor's Information
                </Text>
              </Row>

              <Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
                <Col span={12} style={styles.add_info}>
                  <UserDetail
                    icon={UI.INFO_GENDER}
                    name={"Gender"}
                    value={gender}
                  />
                  <UserDetail
                    icon={UI.INFO_BIRTHDATE}
                    name={"Birthdate"}
                    value={birthdate}
                  />
                  <UserDetail
                    icon={UI.INFO_MED_SCHOOL}
                    name={"Medical School"}
                    // value={medschool.map((school, i) => {
                    //   return school.name;
                    // })}
                    value={"-"}
                  />
                  <UserDetail
                    icon={UI.INFO_QUALI_DATE}
                    name={"Qualification"}
                    value={qualification}
                  />
                  <UserDetail icon={UI.INFO_SOURCE} name={"Source"} value="-" />
                  <UserDetail
                    icon={UI.INFO_SPECIALTY}
                    name={"Specialty"}
                    value={specialty}
                  />
                  <UserDetail
                    icon={UI.INFO_HOSPITAL}
                    name={"Hospital Affiliations"}
                    value={hospitals.map((item) => {
                      return item.hospital.name;
                    })}
                  />
                  <UserDetail
                    icon={UI.INFO_JOINED}
                    name={"Joined Advocacy"}
                    value={advocacies.map((advocacy) => {
                      return advocacy.title;
                    })}
                  />
                  <UserDetail
                    icon={UI.INFO_TRAININGS}
                    name={"Trainings Attended"}
                    value="-"
                  />
                  <UserDetail
                    icon={UI.INFO_LANGUAGE}
                    name={"Languages"}
                    value={language}
                  />
                  <UserDetail
                    icon={UI.INFO_HOME}
                    name={"Home Address"}
                    value={address}
                  />
                  <UserDetail
                    icon={UI.INFO_HOME}
                    name={"Maya Account Number"}
                    value={mayaaccountnumber}
                  />
                  <UserDetail
                    icon={UI.INFO_HOME}
                    name={"Bank Name"}
                    value={bankname}
                  />
                  <UserDetail
                    icon={UI.INFO_HOME}
                    name={"Bank Branch"}
                    value={bankbranch}
                  />
                  <UserDetail
                    icon={UI.INFO_HOME}
                    name={"Bank Account Name"}
                    value={bankaccountname}
                  />
                  <UserDetail
                    icon={UI.INFO_HOME}
                    name={"Bank Account Number"}
                    value={bankaccountnumber}
                  />
                </Col>
                <Col span={12} style={styles.add_info}>
                  <UserDetail
                    icon={UI.INFO_PRC_NUM}
                    name={"PRC Number"}
                    value={prc_number}
                  />
                  <UserDetail
                    icon={UI.INFO_PRC}
                    name={"PRC Start Date"}
                    value={prc_start_date}
                  />
                  <UserDetail
                    icon={UI.INFO_PRC}
                    name={"PRC End Date"}
                    value={prc_end_date}
                  />
                  <UserDetail
                    icon={UI.INFO_PRC}
                    name={"PTR Number"}
                    value={ptrNumber}
                  />
                  <UserDetail
                    icon={UI.INFO_PRC}
                    name={"S2 Number"}
                    value={s2Number}
                  />
                </Col>
              </Row>

              {UI.box(20)}
              <View style={{ display: "flex", marginTop: 20 }}>
                <UI.Row
                  _this={_this}
                  breakpoint={920}
                  style={{ width: "100%" }}
                >
                  <Text style={{ ...styles.box_header_text, flex: 1 }}>
                    Update Doctor Application
                  </Text>
                  <Text style={styles.text_2}>
                    Approve/Disapprove Doctor Application
                  </Text>
                </UI.Row>

                <RegistrationStatusAndDoctorTypeRequestSection>
                  <UI.Row
                    breakpoint={1115}
                    style={{ width: "100%" }}
                    _this={_this}
                  >
                    <Row
                      style={{
                        alignSelf: "flex-start",
                        flex: 1,
                        marginTop: "20px",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          marginRight: "auto",
                          display: "block",
                        }}
                      >
                        <Layout.SelectPicker
                          _this={_this}
                          title={"Registration Status"}
                          isRequired={false}
                          state_name={"status"}
                          isDisabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : false
                          }
                          onChange={() => {
                            this.setState({ isChangeStatus: true });
                          }}
                          style={{
                            width: "336px",
                            cursor:
                              doctorData?.provider
                                .isDoctorsOnboardingApproved === "Deleted"
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          <option value=""></option>
                          <option
                            value="Approved"
                            selected={
                              doctorData?.provider
                                .isDoctorsOnboardingApproved === "Approved"
                                ? true
                                : false
                            }
                          >
                            Approved
                          </option>
                          <option
                            value="Disapproved"
                            selected={
                              doctorData?.provider
                                .isDoctorsOnboardingApproved === "Pending"
                                ? true
                                : false
                            }
                          >
                            Pending
                          </option>
                          <option
                            value="Disapproved"
                            selected={
                              doctorData?.provider
                                .isDoctorsOnboardingApproved === "Disapproved"
                                ? true
                                : false
                            }
                          >
                            Disapproved
                          </option>
                          <option
                            value="Deleted"
                            selected={
                              doctorData?.provider
                                .isDoctorsOnboardingApproved === "Deleted"
                                ? true
                                : false
                            }
                          >
                            Deleted
                          </option>
                        </Layout.SelectPicker>
                      </View>

                      <View
                        style={{
                          width: "50%",
                          marginLeft: "auto",
                          display: "block",
                        }}
                      >
                        <Layout.SelectPicker
                          _this={_this}
                          title={"Doctor Type Request"}
                          isRequired={false}
                          isDisabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : this.state.isPendingDoctorTypeRequest === false
                              ? true
                              : false
                          }
                          state_name={"doctorTypeRequestApproved"}
                          onChange={() => {
                            this.setState({ isChangeDoctorTypeRequest: true });
                          }}
                          style={{
                            width: "336px",
                            cursor:
                              doctorData?.provider
                                .isDoctorsOnboardingApproved === "Deleted" ||
                              this.state.isPendingDoctorTypeRequest === false
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          <option
                            value=""
                            selected={this.state.isPendingDoctorTypeRequest}
                          >
                            Pending
                          </option>
                          {this.state.isPendingDoctorTypeRequest === false ? (
                            <option value="noPendingRequest" selected={true}>
                              No Pending Request
                            </option>
                          ) : (
                            ""
                          )}

                          <option
                            value="true"
                            selected={
                              this.state.isPendingDoctorTypeRequest === false &&
                              this.state.doctorTypeRequestApproved === true
                                ? true
                                : false
                            }
                          >
                            Approved
                          </option>
                          <option
                            value="false"
                            selected={
                              this.state.isPendingDoctorTypeRequest === false &&
                              this.state.doctorTypeRequestApproved === false
                                ? true
                                : false
                            }
                          >
                            Denied
                          </option>
                        </Layout.SelectPicker>
                      </View>
                    </Row>
                  </UI.Row>
                </RegistrationStatusAndDoctorTypeRequestSection>

                <DoctorTypeAndProfessionalFeeSection>
                  <UI.Row
                    breakpoint={1115}
                    style={{ width: "100%" }}
                    _this={_this}
                  >
                    <View
                      style={{
                        width: "50%",
                        marginRight: "auto",
                        display: "block",
                      }}
                    >
                      <Layout.SelectPicker
                        _this={_this}
                        title={"Doctor Type"}
                        isRequired={false}
                        isDisabled={
                          doctorData?.provider.isDoctorsOnboardingApproved ===
                          "Deleted"
                            ? true
                            : false
                        }
                        state_name={"doctorType"}
                        onChange={() => {
                          this.setState({ isChangemWellPlus: true });
                        }}
                        style={{
                          width: "336px",
                          cursor:
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <option value=""></option>
                        <option
                          value="true"
                          selected={
                            this.state.isMwellPlusStatus === true ? true : false
                          }
                        >
                          Contracted
                        </option>
                        <option
                          value="false"
                          selected={
                            this.state.isMwellPlusStatus === false
                              ? true
                              : false
                          }
                        >
                          Consulting
                        </option>
                      </Layout.SelectPicker>
                    </View>
                    {doctorData?.isMwellPlus && (
                      <View
                        style={{
                          width: "50%",
                          marginLeft: "auto",
                          display: "block",
                          paddingRight: 20,
                        }}
                      >
                        <InputForm
                          _this={_this}
                          title={"Price Per Consultation (Contracted)"}
                          placeholder={"Price Per Consultation (Contracted)"}
                          onChange={() => {
                            this.setState({
                              isChangeContractedProfessionalFee: true,
                            });
                          }}
                          state_name={"consultation_fee"}
                          isRequired={false}
                          isNumber={true}
                          maxLength={13}
                          isDisabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : false
                          }
                          style={{
                            flex: 1,
                            width: "200px",
                          }}
                          is_onblur={true}
                          onblur_message={Constants.consultation_fee_validation}
                          defaultValue={doctorData.pricePerConsultation}
                        />
                        <InputForm
                          _this={_this}
                          title={"Chat Consultation Price (Contracted)"}
                          placeholder={"Chat Consultation Price"}
                          onChange={() => {
                            this.setState({
                              isChangeContractedProfessionalFee: true,
                            });
                          }}
                          state_name={"chat_fee"}
                          isRequired={false}
                          isNumber={true}
                          maxLength={13}
                          isDisabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : false
                          }
                          style={{
                            flex: 1,
                            width: "200px",
                            marginLeft: 10
                          }}
                          is_onblur={true}
                          onblur_message={Constants.chat_fee_validation}
                          defaultValue={doctorData.pricePerChatConsultation}
                        />
                      </View>
                    )}
                  </UI.Row>
                </DoctorTypeAndProfessionalFeeSection>

                <LGUSection>
                  <UI.Row
                    breakpoint={1115}
                    disabled={true}
                    style={{ width: "100%" }}
                    _this={_this}
                  >
                    <View style={{ flex: 1 }}>
                      <span
                        className="font-semibold"
                        style={{
                          color: UI.colors.primary,
                          fontSize: "15px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >
                        Is the doctor an LGU Partner?
                      </span>
                      {UI.box(20)}
                      <View style={{ flexDirection: "row", gap: 5 }}>
                        <Checkbox
                          _this={_this}
                          text="Yes"
                          is_disabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : false
                          }
                          onClick={(value) => {
                            _this.setState({
                              isLguPartner: true,
                              isChangeLguPartner: true,
                            });
                          }}
                          is_checked={this.state.isLguPartner}
                          color={"#2E2E2E"}
                        />
                        {UI.box(10)}
                        <Checkbox
                          _this={_this}
                          text="No"
                          is_disabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : false
                          }
                          onClick={(value) => {
                            _this.setState({
                              isLguPartner: false,
                              isChangeLguPartner: true,
                            });
                          }}
                          is_checked={!this.state.isLguPartner}
                          color={"#2E2E2E"}
                        />
                      </View>
                    </View>

                    {this.state.isLguPartner && (
                      <View
                        style={{
                          width: "0%",
                          marginLeft: "auto",
                          display: "block",
                          cursor:
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <Layout.SelectPicker
                          _this={_this}
                          title={"Select Location"}
                          isRequired={false}
                          state_name={"usergroup"}
                          onChange={() => {
                            // console.log(this.state.selectedLgu);
                            this.setState({ isChangeLguPartner: true });
                          }}
                          isDisabled={
                            doctorData?.provider.isDoctorsOnboardingApproved ===
                            "Deleted"
                              ? true
                              : false
                          }
                          style={{
                            width: "336px",
                          }}
                        >
                          <option value="">Select Location</option>
                          {this.state.lgu?.map((item) => {
                            const select_default_lgu_value =
                              this.state.selectedLgu?.find(
                                (res) =>
                                  res.userGroup === item.userGroup && item.name
                              ); // select default lgu if the doctor is isLguPartner is true
                            return (
                              <option
                                value={item.userGroup}
                                selected={
                                  select_default_lgu_value
                                    ? select_default_lgu_value?.name
                                    : ""
                                }
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </Layout.SelectPicker>
                      </View>
                    )}

                    <View style={{ flex: 1 }}></View>
                  </UI.Row>
                </LGUSection>

                <ExclusiveMemberSection>
                  <UI.Row
                    breakpoint={1115}
                    disabled={true}
                    style={{ width: "100%" }}
                    _this={_this}
                  >
                    <View style={{ flex: 2, width: "50%" }}>
                      <span
                        className="font-semibold"
                        style={{
                          color: UI.colors.primary,
                          fontSize: "15px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >
                        Is the doctor exclusive to memberships?
                      </span>
                      {UI.box(20)}
                      <View style={{ flexDirection: "row", gap: 5 }}>
                        <Checkbox
                          _this={_this}
                          text="Yes"
                          onClick={(value) => {
                            _this.setState({
                              isChangeExclusiveMember: true,
                              isExclusiveToMemberships: true,
                            });
                          }}
                          is_checked={this.state.isExclusiveToMemberships}
                          color={"#2E2E2E"}
                        />
                        {UI.box(10)}
                        <Checkbox
                          _this={_this}
                          text="No"
                          onClick={(value) => {
                            _this.setState({
                              isChangeExclusiveMember: true,
                              isExclusiveToMemberships: false,
                            });
                          }}
                          is_checked={!this.state.isExclusiveToMemberships}
                          color={"#2E2E2E"}
                        />
                      </View>
                    </View>

                    <View style={{ flex: 1, width: "25%" }}>
                      <Layout.SelectPicker
                        isMultiple
                        _this={_this}
                        title="Memberships"
                        onChange={() => {
                          this.setState({ isChangeDoctorMemberships: true });
                        }}
                        state_name={"doctor_memberships"}
                      >
                        <option value="">Select Memberships</option>
                        {this.state.memberships.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Layout.SelectPicker>
                    </View>
                    <View style={{ flex: 1 }} />
                  </UI.Row>
                </ExclusiveMemberSection>

                {this.isOneLifeDoctorFeatureEnabled && (
                  <OneLifeDoctorSection>
                    <UI.Row
                      breakpoint={1115}
                      disabled={true}
                      style={{ width: "100%" }}
                      _this={_this}
                    >
                      <View style={{ flex: 1 }}>
                        <span
                          className="font-semibold"
                          style={{
                            color: UI.colors.primary,
                            fontSize: "15px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                        >
                          Is the doctor part of 1Life?
                        </span>
                        {UI.box(20)}
                        <View style={{ flexDirection: "row", gap: 5 }}>
                          <Checkbox
                            _this={_this}
                            text="Yes"
                            onClick={(value) => {
                              _this.setState({
                                isChangeOneLifeDoctor: true,
                                isOneLifeDoctor: true,
                              });
                            }}
                            is_checked={this.state.isOneLifeDoctor}
                            color={"#2E2E2E"}
                          />
                          {UI.box(10)}
                          <Checkbox
                            _this={_this}
                            text="No"
                            onClick={(value) => {
                              _this.setState({
                                isChangeOneLifeDoctor: true,
                                isOneLifeDoctor: false,
                              });
                            }}
                            is_checked={!this.state.isOneLifeDoctor}
                            color={"#2E2E2E"}
                          />
                        </View>
                      </View>
                    </UI.Row>
                  </OneLifeDoctorSection>
                )}

                {doctorData?.provider?.hasOwnProperty(
                  "isAdvocacyOnboardingApproved"
                ) && (
                  <>
                    <View style={{ alignSelf: "flex-start", marginBottom: 15 }}>
                      <Layout.SelectPicker
                        isDisabled={
                          doctorData?.provider?.isAdvocacyOnboardingApproved
                            .step1?.status === "Approved" ||
                          doctorData?.provider?.isAdvocacyOnboardingApproved
                            .step1?.status === "Approve"
                            ? true
                            : false
                        }
                        _this={_this}
                        title={"Advocacy Step 1 Registration Status"}
                        isRequired={false}
                        state_name={"advocacy_doctor_status_step1"}
                        onChange={() => {
                          this.setState({
                            isChangeAdvocacyDoctorStatus: true,
                            isChangeStep1: true,
                          });
                        }}
                        style={{
                          width: "336px",
                          cursor: `${
                            doctorData?.provider?.isAdvocacyOnboardingApproved
                              .step2?.status === "Approved" ||
                            doctorData?.provider?.isAdvocacyOnboardingApproved
                              .step2?.status === "Approve"
                              ? "not-allowed"
                              : "pointer"
                          }`,
                        }}
                      >
                        <option value="">Select Status</option>
                        <option
                          value="1"
                          selected={
                            this.state.advocacy_doctor_status_step1 === "1"
                              ? true
                              : false
                          }
                        >
                          Approved - PRC
                        </option>
                        <option
                          value="2"
                          selected={
                            this.state.advocacy_doctor_status_step1 === "2"
                              ? true
                              : false
                          }
                        >
                          Disapproved
                        </option>
                        <option value="false">Disapproved</option>
                      </Layout.SelectPicker>
                      {(doctorData?.provider?.isAdvocacyOnboardingApproved.step1
                        ?.status === "Approved" ||
                        doctorData?.provider?.isAdvocacyOnboardingApproved.step1
                          ?.status === "Approve") && (
                        <View style={{ position: "relative" }}>
                          <CopyToClipboard
                            text={`${Constants.WEB_HOST_NAME_URL}/advocacy-doctor-register?step=2&advocacy=${doctorData.advocacies[0]?._id}&practitionerID=${doctorData?.id}`}
                          >
                            <a
                              style={{
                                textDecoration: "underline",
                                borderRadius: "7px",
                                padding: "5px 8px",
                                fontSize: "14px",
                                flex: 1,
                                color: "#2E2E2E",
                                fontFamily: "Poppins",
                              }}
                              href="##"
                              onClick={() => {
                                this.setState({ isCopyLinkStep1: true });
                                setTimeout(() => {
                                  this.setState({ isCopyLinkStep1: false });
                                }, 2000);
                              }}
                            >
                              Click me to copy the link for step 2
                            </a>
                          </CopyToClipboard>
                          <span
                            style={{
                              position: "absolute",
                              top: 0,
                              left: "98%",
                              color: UI.colors.inactive,
                            }}
                          >
                            {this.state.isCopyLinkStep1 && "copied"}
                          </span>
                        </View>
                      )}
                    </View>

                    {(doctorData?.provider?.isAdvocacyOnboardingApproved.step1
                      ?.status === "Approved" ||
                      doctorData?.provider?.isAdvocacyOnboardingApproved.step1
                        ?.status === "Approve") && (
                      <View
                        style={{ alignSelf: "flex-start", marginBottom: 15 }}
                      >
                        <Layout.SelectPicker
                          isDisabled={
                            doctorData?.provider?.isAdvocacyOnboardingApproved
                              .step2?.status === "Approved" ||
                            doctorData?.provider?.isAdvocacyOnboardingApproved
                              .step2?.status === "Approve"
                              ? true
                              : false
                          }
                          _this={_this}
                          title={"Advocacy Step 2 Registration Status"}
                          isRequired={false}
                          state_name={"advocacy_doctor_status_step2"}
                          onChange={() => {
                            this.setState({
                              isChangeAdvocacyDoctorStatus: true,
                              isChangeStep2: true,
                            });
                          }}
                          style={{
                            width: "336px",
                            cursor: `${
                              doctorData?.provider?.isAdvocacyOnboardingApproved
                                .step2?.status === "Approved" ||
                              doctorData?.provider?.isAdvocacyOnboardingApproved
                                .step2?.status === "Approve"
                                ? "not-allowed"
                                : "pointer"
                            }`,
                          }}
                        >
                          <option value="">Select Status</option>
                          <option
                            value="2"
                            selected={
                              this.state.advocacy_doctor_status_step2 === "2"
                                ? true
                                : false
                            }
                          >
                            Approved Ready To Upload
                          </option>
                          <option
                            value="3"
                            selected={
                              this.state.advocacy_doctor_status_step2 === "3"
                                ? true
                                : false
                            }
                          >
                            Disapproved
                          </option>
                          <option value="false">Disapproved</option>
                        </Layout.SelectPicker>

                        {(doctorData?.provider?.isAdvocacyOnboardingApproved
                          .step2?.status === "Approved" ||
                          doctorData?.provider?.isAdvocacyOnboardingApproved
                            .step2?.status === "Approve") && (
                          <View style={{ position: "relative" }}>
                            <CopyToClipboard
                              text={`${Constants.WEB_HOST_NAME_URL}/advocacy-doctor-register?step=3&advocacy=${doctorData.advocacies[0]?._id}&practitionerID=${doctorData?.id}`}
                            >
                              <a
                                style={{
                                  textDecoration: "underline",
                                  borderRadius: "7px",
                                  padding: "5px 8px",
                                  fontSize: "14px",
                                  flex: 1,
                                  color: "#2E2E2E",
                                  fontFamily: "Poppins",
                                }}
                                href="##"
                                onClick={() => {
                                  this.setState({ isCopyLinkStep2: true });
                                  setTimeout(() => {
                                    this.setState({ isCopyLinkStep2: false });
                                  }, 2000);
                                }}
                              >
                                Click me to copy the link for step 3
                              </a>
                            </CopyToClipboard>
                            <span
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: UI.colors.inactive,
                              }}
                            >
                              {this.state.isCopyLinkStep2 && "copied"}
                            </span>
                          </View>
                        )}
                      </View>
                    )}

                    {(doctorData?.provider?.isAdvocacyOnboardingApproved.step2
                      ?.status === "Approved" ||
                      doctorData?.provider?.isAdvocacyOnboardingApproved.step2
                        ?.status === "Approve") && (
                      <View
                        style={{ alignSelf: "flex-start", marginBottom: 15 }}
                      >
                        <Layout.SelectPicker
                          isDisabled={
                            doctorData?.provider?.isAdvocacyOnboardingApproved
                              .step3?.status === "Approved" ||
                            doctorData?.provider?.isAdvocacyOnboardingApproved
                              .step3?.status === "Approve"
                              ? true
                              : false
                          }
                          _this={_this}
                          title={"Advocacy Step 3 Registration Status"}
                          isRequired={false}
                          state_name={"advocacy_doctor_status_step3"}
                          onChange={() => {
                            this.setState({
                              isChangeAdvocacyDoctorStatus: true,
                              isChangeStep3: true,
                            });
                          }}
                          style={{
                            width: "336px",
                            cursor: `${
                              doctorData?.provider?.isAdvocacyOnboardingApproved
                                .step2?.status === "Approved" ||
                              doctorData?.provider?.isAdvocacyOnboardingApproved
                                .step2?.status === "Approve"
                                ? "not-allowed"
                                : "pointer"
                            }`,
                          }}
                        >
                          <option value="">Select Status</option>
                          <option
                            value="1"
                            selected={
                              this.state.advocacy_doctor_status_step3 === "1"
                                ? true
                                : false
                            }
                          >
                            Approved
                          </option>
                          <option
                            value="2"
                            selected={
                              this.state.advocacy_doctor_status_step3 === "2"
                                ? true
                                : false
                            }
                          >
                            Disapproved
                          </option>
                          <option value="false">Disapproved</option>
                        </Layout.SelectPicker>
                      </View>
                    )}
                  </>
                )}

                <UploadReceiptSection>
                  <UI.Row
                    breakpoint={1115}
                    style={{ width: "100%" }}
                    _this={_this}
                  >
                    <View style={{ flex: 1 }}>
                      <span
                        className="font-semibold mb-3"
                        style={{
                          color: UI.colors.primary,
                          fontSize: "15px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >
                        Uploaded Receipt
                      </span>

                      <View style={{ gap: 5 }}>
                        {this.state.officalReceipts?.map((or, key) => {
                          return (
                            <div key={key} className="flex gap-3 items-center">
                              <span className="font-semibold">
                                {or.createdAt
                                  ? moment(or.orDate).format("MM/DD/YYYY")
                                  : ""}
                              </span>
                              <PreviewFile
                                _this={_this}
                                {...this.state[
                                  "downloaded_officalReceipts" + key
                                ]}
                              />
                            </div>
                          );
                        })}
                      </View>
                    </View>

                    <View style={{ flex: 1 }}></View>
                    <View style={{ flex: 1 }}></View>
                  </UI.Row>
                </UploadReceiptSection>

                <InputForm
                  _this={_this}
                  title={"Remarks"}
                  placeholder={"Enter Remarks Here"}
                  state_name={"remarks"}
                  style={{
                    flex: 1,
                    marginTop: 10,
                    display:
                      this.state.status === "false" ||
                      this.state.advocacy_doctor_status_step1 === "2" ||
                      this.state.advocacy_doctor_status_step2 === "3" ||
                      this.state.advocacy_doctor_status_step3 === "2"
                        ? "flex"
                        : "none",
                  }}
                  height={60}
                  isRequired={true}
                  type={"big"}
                  maxLength={UI.Constants.limits.description}
                />

                <View style={{ width: width > 720 ? 300 : "100%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <Layout.Btn
                      color={UI.colors.secondary}
                      isDisabled={
                        doctorData?.provider.isDoctorsOnboardingApproved ===
                        "Deleted"
                          ? true
                          : false
                      }
                      onClick={() => {
                        this.submit();
                      }}
                      style={{
                        cursor:
                          doctorData?.provider.isDoctorsOnboardingApproved ===
                          "Deleted"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      text={"SAVE CHANGES"}
                    />
                    {UI.box(20)}

                    <Layout.Btn
                      color={"#E00000"}
                      onClick={() => {
                        UI.goBack();
                      }}
                      text={"CANCEL"}
                    />
                  </View>
                </View>
              </View>
            </StyledTabsTabPane>
            <StyledTabsTabPane tab="Doctor Files" key="2">
              <DownloadFilesSection>
                <UI.Row
                  breakpoint={1115} /*
                  breakpoint_2={720}
                  breakpoint_3={630} */
                  style={{ width: "100%" }}
                  _this={_this}
                >
                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={prcLicensePhoto_filename}
                      filesize={prcLicensePhoto_size}
                      link={prcLicensePhoto}
                      name={name}
                      title={"PRC License Photo"}
                    />
                    <PreviewFile
                      _this={_this}
                      {...downloaded_prc_license_photo}
                    />
                  </View>
                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={signature_filename}
                      filesize={signature_size}
                      link={signature}
                      name={name}
                      title={"Signature"}
                    />
                    <PreviewFile _this={_this} {...downloaded_signature} />
                  </View>
                </UI.Row>
                {/* {UI.box(20)} */}

                <UI.Row
                  breakpoint={1115} /*
                  breakpoint_2={720}
                  breakpoint_3={630} */
                  style={{ width: "100%" }}
                  _this={_this}
                >
                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={moa_filename}
                      filesize={moa_size}
                      link={moaPath}
                      name={name}
                      title={"Memorandum Of Agreement"}
                    />
                    <PreviewFile _this={_this} {...downloaded_moaPath} />
                  </View>

                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={mc_filename}
                      filesize={mc_size}
                      link={marketingConsentPath}
                      name={name}
                      title={"Marketing Consent"}
                    />
                    <PreviewFile _this={_this} {...downloaded_mcPath} />
                  </View>
                </UI.Row>

                <UI.Row
                  breakpoint={1115}
                  style={{ width: "100%" }}
                  _this={_this}
                >
                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={mpc_filename}
                      filesize={mpc_size}
                      link={mpc}
                      name={name}
                      title={"Medical Practitioner's Certificate"}
                    />
                    <PreviewFile _this={_this} {...downloaded_mpc} />
                  </View>

                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={cor_filename}
                      filesize={cor_size}
                      link={certificateOfRegistration}
                      name={name}
                      title={"BIR Form 2303 (Certificate of Registration)"}
                    />
                    <PreviewFile _this={_this} {...downloaded_cor} />
                  </View>
                </UI.Row>

                <UI.Row
                  breakpoint={1115}
                  style={{ width: "100%" }}
                  _this={_this}
                >
                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={ptr_filename}
                      filesize={ptr_size}
                      link={ptr}
                      name={name}
                      title={"PTR Number"}
                    />
                    <PreviewFile _this={_this} {...downloaded_ptr} />
                  </View>

                  <View style={{ alignSelf: "flex-start", flex: 1 }}>
                    <FileDownload
                      _this={_this}
                      filename={s2_filename}
                      filesize={s2_size}
                      link={s2}
                      name={name}
                      title={"S2 Number"}
                    />
                    <PreviewFile _this={_this} {...downloaded_s2} />
                  </View>
                </UI.Row>
              </DownloadFilesSection>

              <UI.Row
                breakpoint={1115}
                style={{ width: "100%", marginTop: "50px" }}
                _this={_this}
              >
                <View
                  style={{
                    alignItems: "center",
                    flex: 1,
                    flexDirection: "space-between",
                  }}
                >
                  <Text style={{ ...styles.box_header_text, flex: 1 }}>
                    BIR FORM 2307
                  </Text>
                  <Layout.Btn
                    color={UI.colors.secondary}
                    text={"Upload BIR Form"}
                    onClick={() => {
                      this.upload_bir();

                      // this.upload_picture();
                    }}
                    icon={UI.UPLOAD_IMAGE}
                    width={220}
                  />
                </View>
              </UI.Row>
              <UI.Row breakpoint={1115} style={{ width: "100%" }} _this={_this}>
                <DoctorTaxTable _this={_this} />
              </UI.Row>
            </StyledTabsTabPane>
          </Tabs>
        </View>
      </MainLayout>
    );
  }
}

const FileDownload = (
  props = {
    title: "",
    filename: "",
    filesize: "",
    onClick: () => {},
    link: "",
    name: "",
    _this: null,
  }
) => {
  const { _this } = props;
  const { width } = _this.state;

  return (
    <div
      // href={props.link}
      // download={props.title + " " + props.name + "-" + props.filename}
      style={{
        flex: 1,
        marginTop: 10,
        // , width: 180
      }}
    >
      <View>
        <Text style={styles.details_name}>{props.title}</Text>
        {UI.box(5)}

        <UI.Row
          breakpoint={600}
          _this={_this}
          style={{ alignItems: width > 600 ? "center" : "flex-start" }}
        >
          <Layout.Btn
            color={UI.colors.secondary}
            text={"Download File"}
            onClick={async () => {
              console.log("download start");

              const downloadResult = await fetch(props.link);

              const blob = await downloadResult.blob();
              const file_type = blob.type.split("/")[1];
              if (file_type) {
                if (file_type === "html") {
                  return false;
                } else {
                  saveAs(
                    blob,
                    props.title + " " + props.name + "-" + props.filename
                  );
                }
              } else {
                return false;
              }

              // const fetchResult = await fetch(
              //   props.link
              // );
              // const json = await fetchResult.json();
              // console.log("onClick finished: ", JSON.stringify(json));
            }}
            width={180}
          />
          {UI.box(20)}
          <Wrap style={{ maxWidth: 300 }}>
            <Text style={styles.details_name}>
              {props.filename} | {props.filesize}
            </Text>
          </Wrap>
        </UI.Row>
      </View>
    </div>
  );
};

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 150, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.user_details_name}>{props.name}</Text>
      </View>
      <Text style={{ ...styles.details_value, paddingLeft: 15 }}>
        {props.value}
      </Text>
    </View>
  );
};

const DownloadFilesSection = styled(Row)`
  margin-bottom: 20px;
`;

const RegistrationStatusAndDoctorTypeRequestSection = styled(Row)`
  margin-bottom: 20px;
`;

const DoctorTypeAndProfessionalFeeSection = styled(Row)`
  margin-bottom: 20px;
  height: 82.27px; // to avoid downward propagation border widths
`;

const LGUSection = styled(Row)`
  height: 81px;
  margin-bottom: 30px;
`;

const ExclusiveMemberSection = styled(LGUSection)``;

const OneLifeDoctorSection = styled(LGUSection)``;

const UploadReceiptSection = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const styles = StyleSheet.create({
  add_info: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  text_2: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#D6D6D6",
    marginRight: "35px",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  user_details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: "15px",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 3px 10px #BFBFBF29",
    padding: "40px 35px 40px 35px",
  },
  inner_box_content: {
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 3px 10px #BFBFBF29",
    padding: "40px 35px 40px 35px",
  },
  box_header_text: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

const StyledTabsTabPane = styled(Tabs.TabPane)`
  .ant-tabs .ant-tabs-top .ant-tabs-nav-list {
    font-family: Poppins !important;
  }
`;
