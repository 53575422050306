import { CallClient, LocalVideoStream, VideoStreamRenderer, 
  // LatestNetworkDiagnostics
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import React, { Component } from 'react'
import UI from '../../shared/Components/UI/js';
// import r from "../../shared/Components/Request/js.js";
// import { r } from '../../shared/custom-react-native';
// import MainLayout from '../_components/MainLayout';
import Websocket from "../../shared/Components/Websocket";
// import Mobile from './Mobile'
import Encounter from "./Encounter";
import PatientDetailsModal from "./PatientDetailsModal";
import Loading from "../_components/Loading";
import { StyleSheet, View, mem } from "../../shared/custom-react-native";
import Modal, { Success, Failed } from "../_components/Modal";
import ImageViewerModal from "../_components/ImageViewerModal";
import moment from "moment";
import APIConstants from "../../shared/modules/APIConstants";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { unescape } from "he";
import jwt from "jsonwebtoken";
import styled from "styled-components";

let localVideoContainer;
let remoteVideoContainer;
const Constants = new APIConstants();

export default class index extends Component {
  tokenCredential;
  callAgent;
  deviceManager;
  call;
  incomingCall;
  localVideoStream;
  localVideoStreamRenderer;
  connection;
  ws;
  appointment;

  constructor(props) {
    super(props);
    
    this.state = {
      message: [],
      patientUserData:[],
      autoSaving: false,
      autoSaveFailed: false,
      lastSavedDate: '',
    };

    const query = new URLSearchParams(this.props.location.search); // get query string from URL

    const sample_date = new Date();
    sample_date.setSeconds(sample_date.getSeconds() + 300000);

    UI.initiateView(this, {
      //Initial State Here
      link: "",
      unique_identifier: UI.uniqid("id"),
      will_wait: false,
      wait_until: sample_date,
      error_message: "",
      participants: [],

      show_loading: false,
      loading_msg: "",
      loading_opaque: true,

      remote_id: "",
      did_accept: false,

      will_show_video: false,
      show_remote_video: false,
      show_loading_initialize: true,
      will_talk: false,

      local_name: "",
      remote_name: "",
      encounter_notes: [],
      self_notes: [],
      appointment:null,
      appointmentIDs: query.get('appointment_id'),

      patientFirstName: "",
      patientMiddleName: "",
      patientLastName: "",
      patientDOB: "",
      genderSelected: "",
    });
    this.btn_show_modal = React.createRef();
    const appointment_id = query.get('appointment_id');
    this.get_appointment(appointment_id)
  }

  initialize_call_agent = async (token) => {
    try {
      // prepare token device
      // console.info("parepare to load token device");
      const callClient = new CallClient(); 
      const tokenCredential = new AzureCommunicationTokenCredential(token.trim());
      const callAgent = await callClient.createCallAgent(tokenCredential);

      // Set up a camera device to use.
      // console.info("parepare to setup camera");
      const deviceManager = await callClient.getDeviceManager();
      await deviceManager.askDevicePermission({ video: true });
      await deviceManager.askDevicePermission({ audio: true });
      this.deviceManager = deviceManager;

      // Listen for an incoming call to accept.
      callAgent.on('incomingCall', async (args) => {
        try {
          // console.info("incoming call received && prepare accept caller users");
          this.incomingCall = args.incomingCall;
          this.setState({ will_show_video: true })
          this.setState({ will_talk: true });
          await this.acceptCallUser();
          await this.call.unmute();
        } catch (error) {
          // console.error('Failed to accept call incoming call', error);
        }
      });
      
      this.callAgent = callAgent;
      // console.info("successfully loaded device token");
    } catch(error) {
      
    }
  }

  acceptCallUser = async () => {
    try {
      const localVideoStream = await this.createLocalVideoStream();
      const videoOptions = localVideoStream ? { localVideoStreams: [localVideoStream] } : undefined;
      this.call = await this.incomingCall.accept({ videoOptions });
      // console.info("successfully accept call user");
      
      // console.info("prepare video streams and subscribeToCall");
      this.localVideoStream = localVideoStream;
      this.subscribeToCall(this.call);

    } catch (error) {
      // console.error(error);
    }
  }

  createLocalVideoStream = async () => {
    // console.info("prepare local video stream && get cameras");
    const camera = (await this.deviceManager.getCameras())[0];
    if (camera) {
      return new LocalVideoStream(camera);
    } else {
      // console.error(`No camera device found on the system`);
    }
  }

  // Display your local video stream preview in your UI
  displayLocalVideoStream = async () => {
    try {
        this.localVideoStreamRenderer = new VideoStreamRenderer(this.localVideoStream);
        const view = await this.localVideoStreamRenderer.createView();
        localVideoContainer.appendChild(view.target);
        // console.log("start show local video container")
        // this.setState({ will_show_video: true });
    } catch (error) {
        console.error(error);
    } 
  }

  // Remove your local video stream preview from your UI
  // removeLocalVideoStream = async() => {
  //   try {
  //     this.localVideoStreamRenderer.dispose();
  //     document.getElementById('localVideoContainer').hidden = true;
  //   } catch (error) {
  //     console.error(error);
  //   } 
  // }

  // Subscribe to a call obj.
  // Listen for property changes and collection updates.
  subscribeToCall = (call) => {
    try {
      // Inspect the initial call.id value.
      // console.log(`Call Ids: ${call.id}`);
      this.saveCallerID(this.state.appointmentIDs, call.id)
      // console.log(">>>>>>")
      //Subscribe to call's 'idChanged' event for value changes.
      call.on('idChanged', () => {
          console.log(`Call Id changed: ${call.id}`); 
      });

      
      // Inspect the initial call.state value.
      // console.log(`Call state: ${call.state}`);
      // console.log(this.state.appointment)
      // Subscribe to call's 'stateChanged' event for value changes.
      call.on('stateChanged', async () => {
          // console.log(`Call state changed: ${call.state}`);
          if(call.state === 'Connected') {
            this.setState({ show_loading_initialize: false });
          } else if (call.state === 'Disconnected') {
            // console.log(`Call ended, call end reason={code=${call.callEndReason.code}, subCode=${call.callEndReason.subCode}}`, this.state.appointment._id);
          }   
      });

      call.localVideoStreams.forEach(async (lvs) => {
          this.localVideoStream = lvs;
          await this.displayLocalVideoStream();
      });

      call.on('localVideoStreamsUpdated', e => {
          e.added.forEach(async (lvs) => {
            this.localVideoStream = lvs;
            await this.displayLocalVideoStream();
          });
          e.removed.forEach(lvs => {
            this.removeLocalVideoStream();
          });
      });
      
      // Inspect the call's current remote participants and subscribe to them.
      call.remoteParticipants.forEach(remoteParticipant => {
          this.subscribeToRemoteParticipant(remoteParticipant);
      });
      // Subscribe to the call's 'remoteParticipantsUpdated' event to be
      // notified when new participants are added to the call or removed from the call.
      call.on('remoteParticipantsUpdated', e => {
          // Subscribe to new remote participants that are added to the call.
          e.added.forEach(remoteParticipant => {
            this.subscribeToRemoteParticipant(remoteParticipant)
          });
          // Unsubscribe from participants that are removed from the call
          e.removed.forEach(remoteParticipant => {
              // window.location.href = `/onboard/encounter?appointment_id=${this.state.appointment._id}`
              this.stop_video().then(() => this.setState({ will_show_video: false, show_remote_video: false }));
              console.log('Remote participant removed from the call.');
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Subscribe to a remote participant obj.
  // Listen for property changes and collection udpates.
  subscribeToRemoteParticipant = (remoteParticipant) => {
    try {
        // Inspect the initial remoteParticipant.state value.
        // console.log(`Remote participant state: ${remoteParticipant.state}`);
        // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
        remoteParticipant.on('stateChanged', async () => {
            // console.log(`Remote participant state changeds: ${remoteParticipant.state}`);
        });

        // Inspect the remoteParticipants's current videoStreams and subscribe to them.
        remoteParticipant.videoStreams.forEach(remoteVideoStream => {
            this.subscribeToRemoteVideoStream(remoteVideoStream)
        });
        // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
        // notified when the remoteParticiapant adds new videoStreams and removes video streams.
        remoteParticipant.on('videoStreamsUpdated', e => {
            // Subscribe to new remote participant's video streams that were added.
            e.added.forEach(remoteVideoStream => {
              this.subscribeToRemoteVideoStream(remoteVideoStream)
            });
            // Unsubscribe from remote participant's video streams that were removed.
            e.removed.forEach(remoteVideoStream => {
                // this.stop_video();
                // console.log('Remote participant video stream was removed.', remoteVideoStream);
            })
        });
    } catch (error) {
        console.error(error);
    }
  }

  saveCallerID = (appointment_id, callerID) => {
    console.log("doctors_onboarding/appointments/doctor/save-caller-id/"+appointment_id)
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/doctor/save-caller-id/"+appointment_id,
      params: {
        callerID:  callerID,
      },
      onSuccess: (response) => {
        // console.log("success response", callerID)
      }
    });
  }

  subscribeToRemoteVideoStream = async (remoteVideoStream) => {
    // Create a video stream renderer for the remote video stream.
    let videoStreamRenderer = new VideoStreamRenderer(remoteVideoStream);
    let view;
    const renderVideo = async () => {
        try {
            // Create a renderer view for the remote video stream.
            // console.log(videoStreamRenderer, "videoStreamRenderer")
            view = await videoStreamRenderer.createView();
            // Attach the renderer view to the UI.
            // console.log(remoteVideoContainer)
            remoteVideoContainer.appendChild(view.target);
            // console.log(remoteVideoContainer)
        } catch (e) {
            console.warn(`Failed to createView, reason=${e.message}, code=${e.code}`);
        }	
    }
    
    remoteVideoStream.on('isAvailableChanged', async () => {
        // Participant has switched video on.
        if (remoteVideoStream.isAvailable) {
            this.setState({show_remote_video: true})
            await renderVideo();

        // Participant has switched video off.
        } else {
          this.setState({show_remote_video: false})
            if (view) {
                view.dispose();
                view = undefined;
            }
        }
    });

    // Participant has video on initially.
    if (remoteVideoStream.isAvailable) {
        await renderVideo();
    }
  }

  toggle_camera = () => {
    if (this.state.will_show_video) {
      this.setState({
        will_show_video: false,
      });
      this.stop_video();
    } else {
      this.setState({
        will_show_video: true,
      });
      this.start_video();
    }
  };

  start_video = async () => {
    try {
      const localVideoStream = await this.createLocalVideoStream();
      await this.call.startVideo(localVideoStream);
    } catch (error) {
      console.error(error);
    }
  }

  toggle_audio = async () => {
    if (this.state.will_talk) {
      this.setState({
        will_talk: false,
      });
      await this.call.mute();
    } else {
      this.setState({
        will_talk: true,
      });
      await this.call.unmute();
    }
  };

  onCreate = () => {
    localVideoContainer = document.getElementById("localVideoContainer");
    remoteVideoContainer = document.getElementById("remoteVideoContainer");
    this.get_cl()
    this.set_doctor_to_offline();
  };

  get_cl = (params) => {
    this.show_loading();
    const queryParams = {
      limit: params?.limit || 10,
      page: params?.page || 1
    }
    
    if (params?.name) {
      queryParams.name = params?.name
    }
    
    UI.webRequest({
      method: "get",
      url: "/doctors_onboarding/appointments/icd",
      params: queryParams,
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        // console.log(data)
        this.setState(
          {
            cl: data.list,
            // all_data: data,
            // totalPages: data results.total,
          },
          () => {
            //this.reload_table("transactions");
            UI.get_entries(
              data.list,
              params?.limit || 10,
              "icd_10_list",
              params?.page || 1,
              {
                total: data.totalDocs,
                pages: data.totalPages,
              }
            );
          }
        );
      // console.log(params, data.list, '/asdasd')
      if (params?.page) {
        // this.setState({show_modal: true, modal_content: this.state.modal_content })
        this.btn_show_modal.current.click()
      }
      // this.reload_table("icd_10_list");
      this.hide_loading();
      }
    });
  }

  get_appointment = (appointment_id) => {
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/appointments/"+appointment_id,
      params: {},
      onFail: (error) => {
        const error_message = error?.data?.m || "Unable to connect to the server. Please check your internet connection. You will be redirected to the appointment page. Tap Enter Appointment to rejoin.";
        // Connection Error
        this.show_modal(
          <Failed
            title={"CONNECTION ERROR"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
              UI.goTo(`/onboard/dashboard`)
            }}
            hideIcon={true}
            onDismissText={"OK"}
          />
        );
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        const appointment = data?.appointment;
        
        if(appointment?.notes) {
          // this.decodeNotes(appointment?.notes);
        }
        
        const patient = appointment.patient.name[0];
        // const cl = data?.cl.slice(0, 10);
        this.setState({
          patientId: appointment.patient._id,
          genderSelected: appointment.patient.gender.toLocaleLowerCase() === 'male' ? 1 : 0,
        }); 

        UI.set_input_value("patientFirstName", patient.given[0]);
        UI.set_input_value("patientMiddleName", patient.middle ?? "");
        UI.set_input_value("patientLastName", patient.family);
        
        if(appointment?.patient?.birthDate) {
          appointment.patient.birthDate = moment(appointment.patient.birthDate).format('YYYY-MM-DD')
          UI.set_date_value(
            "patientDOB",
            new Date (appointment.patient.birthDate),
            true
          );
        }

        if (appointment.orderStatus === "Completed") {
          this.setState({ appointment: appointment });
          this.reload_table('icd_10_list')
          if (appointment.status === "Booked" || appointment.status === "Started") {
            const doctorToken = appointment.communication.practitioner.token;
            await this.initialize_call_agent(doctorToken);
            await this.doctor_appointment_arrived(appointment_id);
          } else {
            this.setState({ show_loading_initialize: false })
          }
        
        }
      }
    });

  };

  decodeNotes = (jsonObj) => {
    const parentKeys = ['consultations','orders','prescriptions','medicalCertificates'];

    const childKeys = [
      ['assessment','patientInstruction','notes'], // consultation
      ['diagnosticsItem'], // order
      ['notes'], // prescription
      ['fitToWorkOthers','recommendation'], // medical certificate
    ];

    parentKeys.forEach( (item, index) => {
      childKeys[index].forEach( (key) => {
        if(jsonObj[item][0]) {
          // let doc = new DOMParser().parseFromString(jsonObj[item][0][key], "text/html");
          // doc = decode(doc.documentElement.textContent);
          let unescaped = localDecode(jsonObj[item][0][key]);
          unescaped = unescape(unescaped);
          // const unescaped = localDecodeURIComponent(jsonObj[item][0][key]);
          jsonObj[item][0][key] = unescaped;
        }
      })
    })

    function localDecode(str) {
      str = str.replace(/\\/g, '')
      str = str.replace(/x2d/g, '-');
      return str;  
    }
  }

  savePatientData = () => {
    const {
      patientId,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      patientDOB_date,
      genderSelected,
    } = this.state;

    const params = {
      name: {
        "family": patientLastName,
        "given": [patientFirstName],
        "middle": patientMiddleName
      },
      birthDate: patientDOB_date,
      gender: genderSelected ? "male" : "female",
    }

    // console.log("saving patient data:",params);

    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/patients/" + patientId,
      params: params,
      onSuccess: (response) => {
        this.hidePatientDetails();
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Saved Patients Details."}
            onDismiss={() => {
              this.get_appointment(this.state.appointmentIDs)
              this.hide_modal();
            }}
          />
        );
      },
      onFail: (err) => {
        this.hidePatientDetails();
        this.show_modal(
          <Failed description={"An Error occured. Try again later."} onDismiss={this.hide_modal} />
        );
      }
    });
  }

  doctor_appointment_arrived = (appointment_id) => {
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/doctor/arrived/"+appointment_id,
      params: {
        action:  "agree",
      },
      onSuccess: (response) => {},
      onFail: (error) => {
        const error_message = error?.data?.m || "Unable to connect to the server. Please check your internet connection. You will be redirected to the appointment page. Tap Enter Appointment to rejoin.";
        // Connection Error
        this.show_modal(
          <Failed
            title={"CONNECTION ERROR"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
              UI.goTo(`/onboard/dashboard`)
            }}
            hideIcon={error?.data?.m === ""}
            onDismissText={"OK"}
          />
        );
      }
    });
  }

  set_doctor_to_offline = () => {
    // console.log("set doctor to offline")
    const data = JSON.parse(mem.get("user_doctor"));
    const doctorID = data._id;

    this.ws = new Websocket({
      onopen: function() {

        this.ws.send(JSON.stringify({
          action: 'update_doctor_to_offline',
          data: {
            doctorStatus: false,
            doctorID
          }
        }));
      }
    });

  }
  close_both = async () => {
    this.stop_video();
    await this.call.mute();
  };

  end_call = (appointment_id, action = null) => {
    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/doctor/leave/"+appointment_id,
      params: {
        action:  action === null ? "leave" : "return",
      },
      onSuccess: async (response) => {
        // console.log("leave appointment", UI.extract_data(response));
        // this.props.history.push(`/onboard/dashboard`);
        await this.stop_call();
        window.location.href = '/onboard/dashboard';
      },
      onFinish: () => {
        this.hide_loading();
      }
    });
  };

  stop_call = async () => {
    try {
      await this.call.hangUp();
    } catch (error) {
      // console.error(error);
    }
  };
  
  stop_video = async () => {
    try {
      await this.call.stopVideo(this.localVideoStream);
    } catch (error) {
      // console.error(error);
    }
  };

  show_loading = (msg, isTransparent) => {
    this.setState({
      show_loading: true,
      loading_msg: msg,
      loading_opaque: false,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
      loading_msg: "",
      loading_opaque: true,
    });
  };

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  showPatientDetails = () => {
    this.setState({
      showPatientDetails: true,
    });
  }

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  show_image_viewer_modal = (imageContent) => {
    this.setState({
      show_image_viewer_modal: true,
      imageViewerModalContent: imageContent,
    });
  }

  hide_image_viewer_modal = () => {
    this.setState({
      show_image_viewer_modal: false,
      imageViewerModalContent: "",
    });
  }

  downloadPatientFile = (path, fileName) => {
    const url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=users`; // TO-DO: get proper base url
    fetch(url, {
      method: "GET",
      headers: {}
    })
    .then(response => {
      response.arrayBuffer().then(function(buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      console.log(err);
    });
  }
  
  hidePatientDetails = () => {
    this.setState({
      showPatientDetails: false,
    });
  }

  componentDidMount = () => {
    const { _this } = this.props;
    _this.layout = this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;
  };

  saveEntry = (id, payload, appointmentId, onSuccessCallBack, onFailCallBack) => {
    // console.log("id:",id," | sent payload:",payload," | appointment Id:",appointmentId);
    this.setState({
      autoSaving: true,
    })

    payload.isAutosave = true;
    const newPayload = JSON.parse(JSON.stringify(payload));

    if (payload.consultations.length) {
      Object.keys(newPayload.consultations[0].covidAssessment).forEach( key => {
        if(newPayload.consultations[0].covidAssessment[key] === null) {
          delete newPayload.consultations[0].covidAssessment[key]
        }
      });
  
      Object.keys(newPayload.consultations[0].dohCovidReferral).forEach( key => {
        if(newPayload.consultations[0].dohCovidReferral[key] === null) {
          delete newPayload.consultations[0].dohCovidReferral[key]
        }
      });
    }

    // const targetedKeyNames = [
    //   ['assessment','patientInstruction','notes'], // consultation
    //   ['diagnosticsItem'], // order
    //   ['notes'], // prescription
    //   ['fitToWorkOthers','recommendation'], // medical certificate
    // ];

    // try {
    //   Object.keys(newPayload).forEach( (parentKey, index) => {
    //     targetedKeyNames[index]?.forEach( (key) => {
    //       if(newPayload[parentKey][0]) {
    //         // const dirty = encode(newPayload[parentKey][0][key]);
    //         // const dirty = encodeURIComponent(newPayload[parentKey][0][key]);
    //         const dirty = escapeStringRegexp(newPayload[parentKey][0][key]);
    //         const clean = escape(dirty);
    //         newPayload[parentKey][0][key] = clean;
    //       }
    //     })
    //   });
    // } catch (err) {
    //   this.setState({
    //     autoSaving: false,
    //     autoSaveFailed: true,
    //   }, () => {
    //     onFailCallBack(err); // Method found in PatientHistory.js
    //   });
    // }
    
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/clinical-notes/"+appointmentId,
      params: {"encode": jwt.sign(newPayload,'secret')},
      onFail: (err) => {
        this.setState({
          autoSaving: false,
          autoSaveFailed: true,
        }, () => {
          onFailCallBack(err); // Method found in PatientHistory.js
        });
      },
      onSuccess: async (response) => {
        this.setState({
          autoSaving: false,
          autoSaveFailed: false,
          lastSavedDate: UI.getCurrentTimeStampPHByMonthNameDayYear24H(response.data.d.updatedAt),
        }, () => {
          onSuccessCallBack(response); // Method found in PatientHistory.js
        })
      },
      onFinish: () => {
        // props._this.hide_loading();
      },
    });
  }

  saveFieldValueDebounced = AwesomeDebouncePromise(
    this.saveEntry,
    1000,
    { key: (fieldId, value, appointmentId, successCallBack, failCallBack) => fieldId },
  )

  render() {
    const {  loading_opaque } = this.state;
    const { _this } = this.props;

    const localVideo = (
      <div
        id="localVideoContainer"
        style={{
          display: !this.state.will_show_video && 'none',
          // height: "100%",
          // width: "100%",
          borderRadius: 10,
          overflow: "hidden",
          backgroundSize : 'contain'
          // height: 180,
          // width:200,
          // // width: '50%',
          // borderRadius: 10,
          // // ...Shadow._3("#646464"),
          // position: "absolute",
          // right: 38,
          // top: 30,
        }}
      ></div>
    );

    const remoteVideo = (
      <StyledRemoteVideoContainer
        id="remoteVideoContainer"
        style={{
          // display: !this.state.show_remote_video && 'none',
          width: "100%",
          // height:"100%",
          // objectFit: "cover"
        }}
      ></StyledRemoteVideoContainer>
    );

    return (
      <div className='h-full'>
         <View style={styles.main_container}>
           
        <Modal _this={this} visible={this.state.show_modal} />
        <ImageViewerModal _this={this} visible={this.state.show_image_viewer_modal} />

        <PatientDetailsModal 
          _this={this} 
          visible={this.state.showPatientDetails}
        />

        <Loading
          _this={_this}
          visible={this.state.show_loading}
          title={""}
          text={this.state.loading_msg}
          modal_opaque={loading_opaque}
        />
        
        <Encounter  _this={this} localVideo={localVideo} remoteVideo={remoteVideo} />
        </View>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "white"
  },
});

const StyledRemoteVideoContainer = styled('div')`
  & video {
    position:absolute;
    inset: 0;
    object-fit: cover !important;
  }
`